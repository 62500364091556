import React, { useState } from 'react';
import {
  AppBar,
  Button,
  Box,
  Card,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  Slide,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Toolbar,
  Typography,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Moment from 'moment';
import { StyledTableCell } from '../StyledTableCell';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const OrderDetailDialog = ({ orderDetail }) => {
  const [open, setOpen] = useState(false);

  const ccyFormat = (num) => {
    return Number(num).toFixed(2);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClickOpen}>รายละเอียด</Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIosNewIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              รายละเอียดออเดอร์
            </Typography>
          </Toolbar>
        </AppBar>
        {/* <List>
          <Typography>คำสั่งซื้อที่: {orderID}</Typography>
        </List> */}

        <List>
          <ListItem>
            <Typography>คำสั่งซื้อที่: {orderDetail.order_text_id}</Typography>
          </ListItem>
          <ListItem>
            <Typography>
              วันที่สั่งซื้อ:{' '}
              {Moment(orderDetail.created_at).format('DD/MM/YYYY HH:mm:ss')}
            </Typography>
          </ListItem>
          <ListItem>
            <Grid container>
              <Grid item sx={{ marginRight: 1 }}>
                <Typography>สถานะ:</Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={
                    orderDetail.order_status === 'complete'
                      ? { color: 'green' }
                      : { color: 'orange' }
                  }
                >
                  {orderDetail.order_status}
                </Typography>
              </Grid>
            </Grid>
            {/* <Typography
              sx={
                orderDetail.order_status === 'complete'
                  ? { color: 'green' }
                  : { color: 'orange' }
              }
            >
              สถานะ: {orderDetail.order_status}
            </Typography> */}
          </ListItem>
          <ListItem>
            <Card sx={{ marginTop: 4, width: '100%' }}>
              <Box
                sx={{
                  justifyContent: 'center',
                  margin: 4,
                  alignItems: 'center',
                }}
              >
                <Table
                  sx={{ minWidth: 250, marginBlock: 4 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>รายการ</StyledTableCell>
                      <StyledTableCell>ราคาต่อหน่วย</StyledTableCell>
                      <StyledTableCell>จำนวน</StyledTableCell>
                      <StyledTableCell align="right">ราคา</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {JSON.parse(orderDetail.order_details).map((row) => (
                      <TableRow key={row.dealer_cart_item_id}>
                        <TableCell>{row.product_name}</TableCell>
                        <TableCell>{row.dealer_price}</TableCell>
                        <TableCell>{row.quantity}</TableCell>
                        <TableCell align="right">
                          {ccyFormat(row.quantity * row.dealer_price)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <Grid item xs={12} sm container>
                  <Grid item xs container spacing={2}>
                    <Grid item sx={{ marginTop: 1 }}>
                      <Typography>รวมค่าสินค้า</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {ccyFormat(orderDetail.order_totalPrice)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm container>
                  <Grid item xs container spacing={2}>
                    <Grid item sx={{ marginTop: 1 }}>
                      <Typography>ส่วนลด</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography color="red">
                      -{ccyFormat(orderDetail.discount)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm container>
                  <Grid item xs container spacing={2}>
                    <Grid item sx={{ marginTop: 1 }}>
                      <Typography>จำนวนเงินรวมทั้งสิ้น</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography>{ccyFormat(orderDetail.total)}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Card>
            {/* <Box mt={4}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button variant="outlined">กลับไปยังตะกร้าสินค้า</Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={submitOrder}>
                    ยืนยันการสั่งซื้อสินค้า
                  </Button>
                </Grid>
              </Grid>
            </Box> */}
          </ListItem>
          {/* {orderDetail.map((o) => (
              <div className="cart-item" key={o.product_id}>
                <Grid item xs={12} sm container>
                  <Grid item xs container spacing={2}>
                    <Grid item sx={{ marginTop: 1, marginBottom: 1 }}>
                      <Typography>{o.product_name}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>สี</StyledTableCell>
                      {o.product_size.map((column) => (
                        <StyledTableCell key={column.size_id}>
                          {column.size_name}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {o.colors.map((row) => (
                      <TableRow key={row.color_id}>
                        <TableCell>{row.color_name}</TableCell>
                        {o.items
                          .filter((stock) => stock.color_id === row.color_id)
                          .map((size) => (
                            <TableCell key={size.stock_id}>
                              {size.quantity}
                            </TableCell>
                          ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ))} */}
          {/* <Grid item xs={12} sm container sx={{ marginTop: 4 }}>
              {orderInfo.bill_id ? (
                <Button
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: '#9ACD32', color: 'black' }}
                  component={Link}
                  to={`/invoice/${orderInfo.bill_id}`}
                >
                  ดูใบเสร็จ
                </Button>
              ) : null}
            </Grid> */}
        </List>
      </Dialog>
    </>
  );
};
export default OrderDetailDialog;
