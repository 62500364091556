import React, { useState } from 'react';
import { LOGOUT } from '../../../store/actions/types';

import {
  AppBar,
  Avatar,
  // Badge,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import { styled } from '@mui/material/styles';
import { HeaderColor } from '../../../config';

import headerLeft from '../../../assets/images/header-left.png';
import headerRight from '../../../assets/images/header-right.png';
import logo from '../../../assets/images/toys-logo.png';
import { store } from '../../../store';
import { useDispatch } from 'react-redux';
import NavDrawerMenu from './NavDrawerMenu';

// const StyledBadge = styled(Badge)(({ theme }) => ({
//   '& .MuiBadge-badge': {
//     right: -3,
//     top: 4,
//     border: `2px solid ${theme.palette.background.paper}`,
//     padding: '0 4px',
//   },
// }));
const Header = ({ menu }) => {
  const [anchorAppElUser, setAnchorAppElUser] = useState(null);
  const [openAppUserMenu, setOpenAppUserMenu] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const user = store.getState().authReducer.user;
  const dispatch = useDispatch;

  const handleCloseAppUserMenu = () => {
    setAnchorAppElUser(null);
    setOpenAppUserMenu(false);
  };
  const handleOpenAppUserMenu = (event) => {
    console.log('user', user);
    setAnchorAppElUser(event.currentTarget);
    setOpenAppUserMenu(true);
  };
  const userMenu = () => {
    if (user === null) {
      return 'none';
    } else {
      return 'flex';
    }
  };
  const addLineMenu = () => {
    if (user === null) {
      return 'flex';
    } else {
      return 'none';
    }
  };
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const handleLogout = () => {
    dispatch({ type: LOGOUT });
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: `${HeaderColor}`,
          display: { xs: 'flex', md: 'none' },
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1 }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleDrawerOpen}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              {/* <Menu
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {bossMenu.map((menu) => (
                  <MenuItem
                    key={menu.key}
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to={menu.url}
                  >
                    <Typography textAlign="center">{menu.item}</Typography>
                  </MenuItem>
                ))}
              </Menu> */}
            </Box>

            <Box sx={{ flexGrow: 0 }} display={userMenu}>
              <Tooltip title="ตะกร้าสินค้า">
                <IconButton sx={{ mr: 4 }} component={Link} to="/dealer/cart">
                  {/* <StyledBadge badgeContent={total} color="secondary"> */}
                  <ShoppingCartIcon />
                  {/* </StyledBadge> */}
                </IconButton>
              </Tooltip>
              <Tooltip title="ตั้งค่า">
                <IconButton onClick={handleOpenAppUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={user !== null ? user.authEmail : ''}
                    src="/static/images/avatar/2.jpg"
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorAppElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(openAppUserMenu)}
                onClose={handleCloseAppUserMenu}
              >
                <MenuItem
                  onClick={handleCloseAppUserMenu}
                  component={Link}
                  to="/dealer/order/history"
                >
                  <Typography textAlign="center">ประวัติการสั่งซื้อ</Typography>
                </MenuItem>
                <MenuItem
                  onClick={handleCloseAppUserMenu}
                  component={Link}
                  to="/dealer/account"
                >
                  <Typography textAlign="center">บัญชี</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">ออกจากระบบ</Typography>
                </MenuItem>
              </Menu>
            </Box>
			<Box display={addLineMenu}>
              <div>
                <a href="https://lin.ee/0qZRMkY">
                  <img
                    src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png"
                    alt="เพิ่มเพื่อน"
                    height="36"
                    border="0"
                  ></img>
                </a>
              </div>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <NavDrawerMenu
        menu={menu}
        openDrawer={drawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
      <Box
        sx={{
          backgroundColor: `${HeaderColor}`,
          width: '100%',
          height: '190px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: { xs: 'flex', md: 'none' },
          }}
        >
          <Box
            sx={{
              backgroundImage: ` url(${logo})`,
              width: 250,
              height: 250,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              placeItems: 'center',
              marginTop: 3,
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              backgroundImage: ` url(${headerLeft})`,
              width: 200,
              height: 200,
              marginTop: 10,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              placeItems: 'center',
            }}
          ></Box>
          <Box
            sx={{
              backgroundImage: ` url(${logo})`,
              width: 250,
              height: 250,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              placeItems: 'center',
              marginTop: 3,
            }}
          ></Box>
          <Box
            sx={{
              backgroundImage: ` url(${headerRight})`,
              width: 200,
              height: 200,
              marginTop: 10.3,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              placeItems: 'center',
            }}
          ></Box>
        </Box>
      </Box>
    </>
  );
};
export default Header;
