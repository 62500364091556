import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import mockAddress from './new_data.json';
import { ListItem } from '@mui/material';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DealerServices from '../../../services/dealerServices';
// import UserService from '../services/user.service';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
var numberPattern = /\d+/g;

export default function EditAddress({ userID, data, onRefresh }) {
  const [open, setOpen] = useState(false);
  //   const [openWarning, setOpenWarning] = useState(false);
  const [openWarningDelete, setOpenWarningDelete] = useState(false);
  //   const [name, setName] = useState([]);
  const [addressID, setAddressID] = useState([]);
  const [address, setAddress] = useState([]);
  const [province, setProvince] = useState(0); // ต้องเก็บ Index ไว้
  const [provinceInfo, setProvinceInfo] = useState([]);
  const [amphoeInfo, setAmphoeInfo] = useState([]);
  const [tambonInfo, setTambonInfo] = useState([]);
  const [zipcodeInfo, setZipcodeInfo] = useState([]);
  const [amphoe, setAmphoe] = useState(0);
  const [tambon, setTambon] = useState(0);
  //   const [defaltAddress, setDefaultAddress] = useState(false);

  useEffect(() => {
    dealerAddress(data);
  }, [data]);

  //   const closeDialog = () => {
  //     setOpenWarning(false);
  //   };
  const dealerAddress = (data) => {
    const convert = JSON.parse(data.data_index);
    //console.log(convert.province);
    const pInfo = mockAddress[convert.province][0];
    setProvince(convert.province);
    setProvinceInfo(pInfo);
    const mock =
      mockAddress[convert.province][1][convert.amphoe][1][convert.tambon];
    const a = mockAddress[convert.province][1][convert.amphoe][0];

    setAmphoe(convert.amphoe);
    setAmphoeInfo(a);
    setTambon(convert.tambon);
    setTambonInfo(mock[0]);
    setZipcodeInfo(mock[1]);
    setAddress(data.address1);
    setAddressID(data.address_id);
  };
  const handleClickOpen = () => {
    setOpen(true);
    dealerAddress(data);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const handleOnSelectProvince = (value, options) => {
    //console.log(value);
    const key = options.key.match(numberPattern);
    const index = key[0];
    setProvince(index);
    setAmphoe(0);
    setTambon(0);
    setAmphoeInfo([]);
    setTambonInfo([]);
    setZipcodeInfo([]);
    setProvinceInfo(value.target.value);
  };
  const handleOnSelectAmphoe = (value, options) => {
    const key = options.key.match(numberPattern);
    const index = key[0];
    setAmphoe(index);
    setTambonInfo([]);
    setZipcodeInfo([]);
    setAmphoeInfo(value.target.value);
  };
  const handleOnSelectTambon = (value, options) => {
    const key = options.key.match(numberPattern);
    const index = key[0];
    setTambon(index);
    setTambonInfo(value.target.value);
  };

  const handleOnSelectZipcode = (value, options) => {
    setZipcodeInfo(value.target.value);
  };

  const saveNewAddress = async (e) => {
    e.preventDefault();
    // validateDefaultAddress();
    const t = validateTambon();
    const a = validateAmphoe();
    const dataIndex = {
      province: province,
      amphoe: amphoe,
      tambon: tambon,
    };
    const convert = JSON.stringify(dataIndex);
    const data = {
      address_id: addressID,
      dealer_id: userID,
      address1: `${address} `,
      address2: `${t}${tambonInfo} ${a}${amphoeInfo}, จังหวัด${provinceInfo}, ${zipcodeInfo}`,
      data_index: convert,
    };
    //console.log(data);
    try {
      //   console.log(userID, data);
      await DealerServices.updateDealerAddress(userID, data);
      setProvince(0);
      setAmphoe(0);
      setTambon(0);
      setAmphoeInfo([]);
      setTambonInfo([]);
      setZipcodeInfo([]);
      setOpen(false);
      onRefresh();
    } catch (err) {
      console.log(err);
    }
    // try {
    //   await UserService.addAddress(userID, data);
    //   setOpen(false);
    // } catch (err) {
    //   console.log(err);
    // }
    // console.log(data);
  };

  //   const checkDefaultAddress = () => {
  //     if (data.default_address) {
  //       return <Switch disabled defaultChecked />;
  //     } else {
  //       return <Switch checked={defaltAddress} onChange={handleChangeDefault} />;
  //     }
  //   };

  //   const openDeleteDialog = () => {
  //     setOpenWarningDelete(true);
  //   };
  const handleCloseWarningDelete = () => {
    setOpenWarningDelete(false);
  };
  const deleteAddress = async () => {
    try {
      //   await UserService.deleteAddress(addressID);
    } catch (err) {
      console.log(err);
    }
  };

  const validatefield = () => {
    if (
      //   name.length > 0 &&
      address.length > 0 &&
      provinceInfo.length > 0 &&
      amphoeInfo.length > 0 &&
      tambonInfo.length > 0 &&
      zipcodeInfo.length !== 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const validateTambon = () => {
    if (province === '1') {
      const t = 'แขวง';
      return t;
    } else {
      const t = 'ตำบล';
      return t;
    }
  };
  const validateAmphoe = () => {
    //console.log(province);
    if (province === '1') {
      const a = 'เขต';
      return a;
    } else {
      const a = 'อำเภอ';
      return a;
    }
  };
  const renderAddress = () => {
    if (data !== '') {
      return (
        <>
          <List>
            <ListItem>
              <TextField
                label="ที่อยู่"
                type="text"
                fullWidth
                value={address}
                onChange={(event) => setAddress(event.target.value)}
              ></TextField>
            </ListItem>

            <ListItem>
              {' '}
              <FormControl fullWidth>
                <InputLabel id="address-province-label">จังหวัด</InputLabel>
                <Select
                  labelId="address-province-label"
                  id="address-province"
                  value={provinceInfo}
                  label="จังหวัด"
                  onChange={handleOnSelectProvince}
                >
                  {mockAddress.map((province, index) => (
                    <MenuItem key={index} value={province[0]}>
                      {province[0]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ListItem>

            <ListItem>
              {' '}
              <FormControl fullWidth>
                <InputLabel id="address-amphoe-label">เขต/อำเภอ</InputLabel>
                <Select
                  labelId="address-amphoe-label"
                  id="address-amphoe"
                  value={amphoeInfo}
                  label="อำเภอ"
                  onChange={handleOnSelectAmphoe}
                >
                  {mockAddress[province][1].map((amphoe, index) => (
                    <MenuItem key={index} value={amphoe[0]}>
                      {amphoe[0]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ListItem>

            <ListItem>
              <FormControl fullWidth>
                <InputLabel id="address-tambon-label">แขวง/ตำบล</InputLabel>
                <Select
                  labelId="address-tambon-label"
                  id="address-tambon"
                  value={tambonInfo}
                  label="ตำบล"
                  onChange={handleOnSelectTambon}
                >
                  {mockAddress[province][1][amphoe][1].map((tambon, index) => (
                    <MenuItem key={index} value={tambon[0]}>
                      {tambon[0]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl fullWidth>
                <InputLabel id="address-zipcode-label">รหัสไปรษณีย์</InputLabel>
                <Select
                  labelId="address-zipcode-label"
                  id="address-zipcode"
                  value={zipcodeInfo}
                  label="รหัสไปรษณีย์"
                  onChange={handleOnSelectZipcode}
                >
                  {mockAddress[province][1][amphoe][1][tambon][1].map(
                    (zipcode, index) => (
                      <MenuItem key={index} value={zipcode}>
                        {zipcode}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </ListItem>
            <ListItem>
              <Button
                fullWidth
                disabled={validatefield()}
                variant="contained"
                type="submit"
                onClick={saveNewAddress}
              >
                บันทึก
              </Button>
            </ListItem>
          </List>
        </>
      );
    } else {
      return null;
    }
  };
  return (
    <div>
      <Button onClick={handleClickOpen}>แก้ไข</Button>
      {/* <Dialog
        open={openWarning}
        onClose={handleCloseWarning}
        component="form"
        onSubmit={closeDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>ต้องการออกจากหน้านี้</DialogTitle>
        <DialogContent>
          <DialogContentText>
            คุณยังไม่ได้บันทึกที่อยู่ ต้องการออกจากหน้านี้ ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWarning}>ยกเลิก</Button>
          <Button type="submit">ตกลง</Button>
        </DialogActions>
      </Dialog> */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              เพิ่มที่อยู่ใหม่
            </Typography>
          </Toolbar>
        </AppBar>

        <List>
          {renderAddress()}
          {/* <div className="sarid-container">
            <Button
              fullWidth
              variant="outlined"
              type="submit"
              color="warning"
              onClick={openDeleteDialog}
            >
              ลบ
            </Button>
          </div> */}
          <Dialog
            open={openWarningDelete}
            onClose={handleCloseWarningDelete}
            component="form"
            onSubmit={deleteAddress}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>ต้องการออกจากหน้านี้</DialogTitle>
            <DialogContent>
              <DialogContentText>
                คุณยังไม่ได้บันทึกที่อยู่ ต้องการออกจากหน้านี้ ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseWarningDelete}>ยกเลิก</Button>
              <Button type="submit">ตกลง</Button>
            </DialogActions>
          </Dialog>
        </List>
      </Dialog>
    </div>
  );
}
