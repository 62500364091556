import { useState, useEffect } from 'react';
import OrderServices from '../../../../services/orderServices';
import { store } from '../../../../store';
import { Card, Container, Grid, Stack, Typography } from '@mui/material';
import Moment from 'moment';
import OrderDetailDialog from '../../../../components/Dealer/OrderDetailDialog';
import { styled } from '@mui/material/styles';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
}));

const OrderHistory = () => {
  const [orderHistory, setOrderHistory] = useState([]);
  const [orderTotalPrice, setOrderTotalPrice] = useState();
  const user = store.getState().authReducer.user;

  useEffect(() => {
    const getDealerOrder = async () => {
      await OrderServices.getOrderHistory(user.id).then((response) => {
        const calTotal = response
          .map((item) => item.total)
          .reduce((sum, i) => sum + i, 0);
        setOrderTotalPrice(calTotal);
        setOrderHistory(response);
        console.log('Order:', response);
      });
    };
    getDealerOrder();
  }, [user.id]);

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" sx={{ mb: 5 }}>
          ประวัติคำสั่งซื้อ
        </Typography>
      </Stack>
      <Container>
        <Grid container spacing={4} sx={{ marginTop: 5 }}>
          <Grid item xs={12} sm={6} md={3}>
            <RootStyle sx={{ color: '#145A32', backgroundColor: '#73C6B6' }}>
              <Typography variant="h5">สั่งซื้อสินค้า</Typography>
              <Typography variant="h3">{orderHistory.length}</Typography>
              <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                ครั้ง
              </Typography>
            </RootStyle>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <RootStyle sx={{ color: '#145A32', backgroundColor: '#85C1E9' }}>
              <Typography variant="h5">รวมเป็นเงินทั้งสิ้น</Typography>
              <Typography variant="h3">{orderTotalPrice}</Typography>
              <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                บาท
              </Typography>
            </RootStyle>
          </Grid>
        </Grid>
        {orderHistory.map((order) => (
          <div className="information-container" key={order.order_id}>
            <Grid>
              <Typography>คำสั่งซื้อเลขที่: {order.order_text_id}</Typography>
            </Grid>
            <Grid>
              <Typography>
                วันที่สั่งซื้อ:{' '}
                {Moment(order.created_at).format('DD/MM/YYYY HH:mm:ss')}
              </Typography>
            </Grid>
            <Grid>
              <Typography>
                จำนวนเงิน:
                {order.total}
              </Typography>
            </Grid>
            <Grid container>
              <Grid item sx={{ marginRight: 1 }}>
                <Typography>สถานะ:</Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={
                    order.order_status === 'complete'
                      ? { color: 'green' }
                      : { color: 'orange' }
                  }
                >
                  {order.order_status}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs container spacing={2}>
              <Grid item xs></Grid>
              <OrderDetailDialog orderDetail={order} />
            </Grid>
          </div>
        ))}
      </Container>
    </Container>
  );
};

export default OrderHistory;
