import { createStore, applyMiddleware } from 'redux';
// import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';
// import rootReducer from './reducers';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';
// import { useDispatch } from 'react-redux';

// const initialState = {};
// const middleware = [thunk];

// export const store = createStore(
//   rootReducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
// );
// export default store;
// export const persister = persistStore(store);
let middlewares = [thunk];

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);
export const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares)
);

export const persister = persistStore(store);

// const { dispatch } = store
