import React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import DealerServices from '../../../services/dealerServices';
import { useParams } from 'react-router-dom';
import { StyledTableCell } from '../../../components/StyledTableCell';
import OrderServices from '../../../services/orderServices';
import ProductServices from '../../../services/productServices';
import { store } from '../../../store';

const OrderDetails = () => {
  const { id } = useParams();
  const [dealerCartItems, setDealerCartItems] = useState([]);
  //   const [dealerInfo, setDealerInfo] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [total, setTotal] = useState(0);
  const user = store.getState().authReducer.user;
  useEffect(() => {
    const getCart = async () => {
      await DealerServices.getItemsInDealerCart(id).then((res) => {
        setDealerCartItems(res);
        const calTotal = res
          .map((item) => item.quantity * item.dealer_price)
          .reduce((sum, i) => sum + i, 0);
        setTotalPrice(Number(calTotal).toFixed(2));
        DealerServices.getDealerById(id).then((res) => {
          console.log('info: ', res);
          const dis = (calTotal * res.discount) / 100;
          //   setDealerInfo(res);
          setDiscount(Number(dis).toFixed(2));
          setTotal(Number(calTotal - dis).toFixed(2));
        });
        // setDiscount(info.discount);
        // setDiscount((calTotal * info.discount) / 100);
        // console.log('cart: ', res);
      });
    };
    // const getDealerInfo = async () => {
    //   await DealerServices.getDealerById(id).then((res) => {
    //     console.log('info: ', res);
    //     setDealerInfo(res);
    //   });
    // };
    // getDealerInfo();
    getCart();
  }, [id]);

  const ccyFormat = (num) => {
    return Number(num).toFixed(2);
  };

  const submitOrder = async () => {
    const orderData = {
      dealer_id: id,
      order_details: JSON.stringify(dealerCartItems),
      order_totalPrice: Number(totalPrice),
      discount: Number(discount),
      total: Number(total),
    };
    // console.log('item:', dealerCartItems);
    // console.log('orderData', orderData);
    try {
      console.log('Order detail', dealerCartItems);
      for (let i = 0; i < dealerCartItems.length; i++) {
        await ProductServices.decreaseStock(
          dealerCartItems[i].product_id,
          dealerCartItems[i]
        );
      }
      await OrderServices.createOrder(orderData).then((res) => {
        console.log(res);
        window.location = `/dealer/order/success/${user.id}/${res.id}`;
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <Box>
        <Typography variant="h5">
          รายการสินค้าที่ต้องการสั่งซื้อของคุณ
        </Typography>
        <Card sx={{ marginTop: 4 }}>
          <Box
            sx={{
              justifyContent: 'center',
              margin: 4,
              alignItems: 'center',
            }}
          >
            <Table
              sx={{ minWidth: 250, marginBlock: 4 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>รายการ</StyledTableCell>
                  <StyledTableCell>ราคาต่อหน่วย</StyledTableCell>
                  <StyledTableCell>จำนวน</StyledTableCell>
                  <StyledTableCell>ราคา</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dealerCartItems.map((row) => (
                  <TableRow key={row.dealer_cart_item_id}>
                    <TableCell>{row.product_name}</TableCell>
                    <TableCell>{row.dealer_price}</TableCell>
                    <TableCell>{row.quantity}</TableCell>
                    <TableCell>
                      {ccyFormat(row.quantity * row.dealer_price)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Grid item xs={12} sm container>
              <Grid item xs container spacing={2}>
                <Grid item sx={{ marginTop: 1 }}>
                  <Typography>รวมค่าสินค้า</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography>{ccyFormat(totalPrice)}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container spacing={2}>
                <Grid item sx={{ marginTop: 1 }}>
                  <Typography>ส่วนลด</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography color="red">-{ccyFormat(discount)}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container spacing={2}>
                <Grid item sx={{ marginTop: 1 }}>
                  <Typography>จำนวนเงินรวมทั้งสิ้น</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography>{ccyFormat(total)}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Card>
        <Box mt={4}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button variant="outlined" component={Link} to={`/dealer/cart`}>
                กลับไปยังตะกร้าสินค้า
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={submitOrder}>
                ยืนยันการสั่งซื้อสินค้า
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
export default OrderDetails;
