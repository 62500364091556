import React, { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';

import BlogServices from '../../services/blogServices';
import { useParams } from 'react-router';

const Post = () => {
  const { id } = useParams();
  const [blogInfo, setBlogInfo] = useState([]);

  useEffect(() => {
    const getBlog = async () => {
      await BlogServices.getBlogById(id).then((res) => {
        setBlogInfo(res);
      });
    };
    getBlog();
  }, [id]);
  return (
    <Box>
      <Container maxWidth="xl" display="flex">
        <Box sx={{ p: 3, pb: 1 }} dir="ltr" display="relative">
          <div className="upload-blog-image">
            <div className="toy-space-upload-blog">
              <img src={blogInfo.image} alt="" />
            </div>
          </div>
          <Typography variant="h3" sx={{ mt: 4, mb: 4 }}>
            {blogInfo.title}
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: blogInfo.article }} />
        </Box>
      </Container>
    </Box>
  );
};
export default Post;
