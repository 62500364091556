import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import mockAddress from './new_data.json';
import { Switch } from '@mui/material';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, ListItem } from '@mui/material';
import { store } from '../../../store';
import DealerServices from '../../../services/dealerServices';
// import UserService from '../services/user.service';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
var numberPattern = /\d+/g;

export default function AddNewAddress({ userID, isDefault, onRefresh }) {
  const [open, setOpen] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [address, setAddress] = useState([]);
  const [province, setProvince] = useState(0); // ต้องเก็บ Index ไว้
  const [provinceInfo, setProvinceInfo] = useState([]);
  const [amphoeInfo, setAmphoeInfo] = useState([]);
  const [tambonInfo, setTambonInfo] = useState([]);
  const [zipcodeInfo, setZipcodeInfo] = useState([]);
  const [amphoe, setAmphoe] = useState(0);
  const [tambon, setTambon] = useState(0);
  const [defaltAddress, setDefaultAddress] = useState(false);
  const user = store.getState().authReducer.user;

  useEffect(() => {
    if (isDefault) {
      setDefaultAddress(true);
    }
  }, [isDefault]);

  const closeDialog = () => {
    setOpenWarning(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const closeAddAddressDialog = () => {
    setOpenWarning(false);
    setOpen(false);
  };

  const handleClose = () => {
    if (
      address.length > 0 ||
      provinceInfo.length > 0 ||
      amphoeInfo.length > 0 ||
      tambonInfo.length > 0 ||
      zipcodeInfo.length !== 0
    ) {
      setOpenWarning(true);
    } else {
      setOpenWarning(false);
      setOpen(false);
    }
    // setOpenWarning(true);
  };
  const handleCloseWarning = () => {
    setOpenWarning(false);
  };

  const handleChangeDefault = () => {
    setDefaultAddress(!defaltAddress);
  };

  const handleOnSelectProvince = (value, options) => {
    console.log(value);
    const key = options.key.match(numberPattern);
    const index = key[0];
    setProvince(index);
    setAmphoe(0);
    setTambon(0);
    setAmphoeInfo([]);
    setTambonInfo([]);
    setZipcodeInfo([]);
    setProvinceInfo(value.target.value);
  };
  const handleOnSelectAmphoe = (value, options) => {
    const key = options.key.match(numberPattern);
    const index = key[0];
    setAmphoe(index);
    setTambonInfo([]);
    setZipcodeInfo([]);
    setAmphoeInfo(value.target.value);
  };
  const handleOnSelectTambon = (value, options) => {
    const key = options.key.match(numberPattern);
    const index = key[0];
    setTambon(index);
    setTambonInfo(value.target.value);
  };

  const handleOnSelectZipcode = (value, options) => {
    setZipcodeInfo(value.target.value);
  };

  const addNewAddress = async (e) => {
    e.preventDefault();
    // validateDefaultAddress();
    const t = validateTambon();
    const a = validateAmphoe();
    const dataIndex = {
      province: province,
      amphoe: amphoe,
      tambon: tambon,
    };
    const convert = JSON.stringify(dataIndex);
    const data = {
      dealer_id: user.id,
      address1: `${address} `,
      address2: `${t}${tambonInfo} ${a}${amphoeInfo}, จังหวัด${provinceInfo}, ${zipcodeInfo}`,
      default_address: defaltAddress,
      data_index: convert,
    };
    // console.log(data);
    try {
      await DealerServices.createDealerAddress(data).then((res) => {
        // console.log('After add address:', res);
        if (res.status === 200) {
          setOpen(false);
          onRefresh();
        }
      });
    } catch (err) {
      console.log(err);
    }

    // try {
    //   await UserService.addAddress(userID, data);
    //   setProvince(0);
    //   setAmphoe(0);
    //   setTambon(0);
    //   setAmphoeInfo([]);
    //   setTambonInfo([]);
    //   setZipcodeInfo([]);
    //   setOpen(false);
    //   onRefresh();
    // } catch (err) {
    //   console.log(err);
    // }
  };

  const checkDefaultAddress = () => {
    if (isDefault) {
      return <Switch disabled defaultChecked />;
    } else {
      return <Switch checked={defaltAddress} onChange={handleChangeDefault} />;
    }
  };
  //   const validateDefaultAddress = () => {
  //     console.log('isDefault', isDefault);
  //     if (isDefault) {
  //       setDefaultAddress(true);
  //     }
  //   };

  const validatefield = () => {
    if (
      address.length > 0 &&
      provinceInfo.length > 0 &&
      amphoeInfo.length > 0 &&
      tambonInfo.length > 0 &&
      zipcodeInfo.length !== 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const validateTambon = () => {
    if (province === '1') {
      const t = 'แขวง';
      return t;
    } else {
      const t = 'ตำบล';
      return t;
    }
  };
  const validateAmphoe = () => {
    console.log(province);
    if (province === '1') {
      const a = 'เขต';
      return a;
    } else {
      const a = 'อำเภอ';
      return a;
    }
  };
  return (
    <div>
      <Button onClick={handleClickOpen}>เพิ่มที่อยู่</Button>
      <Dialog
        open={openWarning}
        onClose={handleCloseWarning}
        onSubmit={closeDialog}
        fullWidth
      >
        <DialogTitle>ต้องการออกจากหน้านี้</DialogTitle>
        <DialogContent>
          <DialogContentText>
            คุณยังไม่ได้เพิ่มที่อยู่ ต้องการออกจากหน้านี้ ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWarning}>ยกเลิก</Button>
          <Button onClick={closeAddAddressDialog}>ตกลง</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              เพิ่มที่อยู่ใหม่
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem>
            <TextField
              label="ที่อยู่"
              type="text"
              fullWidth
              onChange={(event) => setAddress(event.target.value)}
            ></TextField>
          </ListItem>
          <ListItem>
            <FormControl fullWidth>
              <InputLabel id="address-province-label">จังหวัด</InputLabel>
              <Select
                labelId="address-province-label"
                id="address-province"
                value={provinceInfo}
                label="จังหวัด"
                onChange={handleOnSelectProvince}
              >
                {mockAddress.map((province, index) => (
                  <MenuItem key={index} value={province[0]}>
                    {province[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl fullWidth>
              <InputLabel id="address-amphoe-label">เขต/อำเภอ</InputLabel>
              <Select
                labelId="address-amphoe-label"
                id="address-amphoe"
                value={amphoeInfo}
                label="อำเภอ"
                onChange={handleOnSelectAmphoe}
              >
                {mockAddress[province][1].map((amphoe, index) => (
                  <MenuItem key={index} value={amphoe[0]}>
                    {amphoe[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl fullWidth>
              <InputLabel id="address-tambon-label">แขวง/ตำบล</InputLabel>
              <Select
                labelId="address-tambon-label"
                id="address-tambon"
                value={tambonInfo}
                label="ตำบล"
                onChange={handleOnSelectTambon}
              >
                {mockAddress[province][1][amphoe][1].map((tambon, index) => (
                  <MenuItem key={index} value={tambon[0]}>
                    {tambon[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl fullWidth>
              <InputLabel id="address-zipcode-label">รหัสไปรษณีย์</InputLabel>
              <Select
                labelId="address-zipcode-label"
                id="address-zipcode"
                value={zipcodeInfo}
                label="รหัสไปรษณีย์"
                onChange={handleOnSelectZipcode}
              >
                {mockAddress[province][1][amphoe][1][tambon][1].map(
                  (zipcode, index) => (
                    <MenuItem key={index} value={zipcode}>
                      {zipcode}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </ListItem>
          {/* <ListItem>
            <Grid item xs={12} sm container>
              <Grid item xs container spacing={2}>
                <Grid item sx={{ marginTop: 1 }}>
                  <Typography>ตั้งเป็นค่าที่อยู่เริ่มต้น</Typography>
                </Grid>
              </Grid>
              <Grid item>{checkDefaultAddress()}</Grid>
            </Grid>
          </ListItem> */}
          <ListItem>
            <Button
              fullWidth
              disabled={validatefield()}
              variant="contained"
              type="submit"
              onClick={addNewAddress}
            >
              เพิ่มที่อยู่
            </Button>
          </ListItem>
        </List>
      </Dialog>
    </div>
  );
}
