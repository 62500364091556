import MainLayout from '../layout/MainLayout';
import GuestGuard from '../utils/GuestGuard';
import Dealer from '../pages/Dealer';
import LoginForm from '../pages/Dealer/Login';
import DealerRegister from '../pages/Dealer/Register';
import RegisterSuccess from '../pages/Dealer/Register/register-success';
import Home from '../pages/Home';
import ProductDetails from '../pages/Products/_id';
import Category from '../pages/Products/Category/_id';
import { Navigate } from 'react-router';

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <GuestGuard>
          <MainLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: '/home',
          element: <Home />,
        },
        {
          path: 'products/:id',
          element: <ProductDetails />,
        },
        {
          path: 'products/category/:id',
          element: <Category />,
        },
        {
          path: 'dealer',
          element: <Dealer />,
        },
        {
          path: 'dealer/login',
          element: <LoginForm />,
        },
        {
          path: 'dealer/register',
          element: <DealerRegister />,
        },
        {
          path: '/dealer/register-success/:id',
          element: <RegisterSuccess />,
        },
		{
		  path: '/',
		  element: <Navigate to="/home" replace />,
		},

        //   {
        //     path: 'login',
        //     element: <AuthLogin />
        //   },
        //   {
        //     path: 'register',
        //     element: <AuthRegister />
        //   },
        //   {
        //     path: 'forgot-password',
        //     element: <AuthForgotPassword />
        //   },
        //   {
        //     path: 'check-mail',
        //     element: <AuthCheckMail />
        //   },
        //   {
        //     path: 'reset-password',
        //     element: <AuthResetPassword />
        //   },
        //   {
        //     path: 'code-verification',
        //     element: <AuthCodeVerification />
        //   }
      ],
    },
  ],
};

export default LoginRoutes;
