import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Link } from 'react-router-dom';

import CategoryServices from '../../../services/categoryServices';
import { useDispatch } from 'react-redux';
import { LOGOUT } from '../../../store/actions/types';
import { store } from '../../../store';
// import { styled } from '@mui/material/styles';

// const pages = [
//   { item: 'หน้าแรก', key: 'home', url: '/' },
//   { item: 'หมวดหมู่สินค้า', key: 'category', url: '/category' },
//   { item: 'รู้จักเรา', key: 'about', url: '/about-us' },
//   { item: 'ตัวแทนจำหน่าย', key: 'dealer', url: '/dealer' },
//   { item: 'ติดต่อเรา', key: 'contact', url: '/contact-us' },
// ];
const Navbar = ({ menu }) => {
  const [categories, setCategories] = useState([]);
  const [anchorElUser, setAnchorElUser] = useState(null);
  // const [dropdown, setDropdown] = useState('');
  const [dropdownEl, setDropdownEl] = useState('');
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const authUser = store.getState().authReducer.user;
  // const [dropdownName, setDropdownName] = useState('');

  useEffect(() => {
    const getCat = async () => {
      const response = await CategoryServices.getAllCategories();
      setCategories(response);
      //console.log(response);
    };
    // const getUser = async () => {
    //   console.log('User:', authUser);
    //   const response = await AuthenServices.getCurrentUser();
    //   setUser(response);
    //   // console.log('User:', response);
    // };
    getCat();
    // getUser();
  }, []);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseCatMenu = () => {
    setDropdownEl(null);
    setOpen(false);
  };
  const logout = () => {
    localStorage.removeItem('toysSpaceDealer');
    dispatch({ type: LOGOUT });
    window.location = '/home';
  };
  const userMenu = () => {
    //console.log('AuthUser:', authUser);
    if (authUser !== null) {
      return (
        <>
          {menu.map((m) =>
            m.children ? (
              <Box
                key={m.title}
                mx={1}
                p={3}
                display="flex"
                alignItems="baseline"
                sx={{ cursor: 'pointer' }}
                onMouseEnter={({ currentTarget }) => {
                  // setDropdown(currentTarget);
                  setDropdownEl(currentTarget);
                  setOpen(true);
                }}
                onMouseLeave={() => setOpen(false)}
              >
                <Grid container spacing={0} sx={{ marginTop: 2.5 }}>
                  <Grid item>
                    <Typography sx={{ color: 'black' }}>{m.title}</Typography>
                  </Grid>
                  <Grid item sx={{ marginTop: -0.5 }}>
                    <ExpandMoreOutlinedIcon />
                  </Grid>
                </Grid>
                <Menu
                  sx={{ mt: '70px' }}
                  id="menu-category"
                  anchorEl={dropdownEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={Boolean(open)}
                  onClose={handleCloseCatMenu}
                  PaperProps={{
                    style: {
                      width: 350,
                      backgroundColor: '#85C1E9',
                    },
                  }}
                >
                  {categories.map((cat, index) => (
                    <MenuItem
                      onClick={handleCloseCatMenu}
                      component={Link}
                      to={{
                        pathname: `/dealer/products/category/${cat.category_id}`,
                      }}
                      key={index}
                    >
                      <Typography textAlign="center">
                        {cat.category_name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            ) : (
              <Box
                key={m.title}
                component={Link}
                to={m.path}
                mx={1}
                p={3}
                display="flex"
                alignItems="baseline"
                sx={{ cursor: 'pointer' }}
                style={{ textDecoration: 'none' }}
                underline="none"
              >
                <Typography sx={{ color: 'black' }}>{m.title}</Typography>
              </Box>
            )
          )}
          {/* <Box
            component={Link}
            to="/dealer/home"
            mx={1}
            p={3}
            display="flex"
            alignItems="baseline"
            sx={{ cursor: 'pointer' }}
            style={{ textDecoration: 'none' }}
            underline="none"
          >
            <Typography sx={{ color: 'black' }}>หน้าแรก</Typography>
          </Box>
          <Box
            mx={1}
            p={3}
            display="flex"
            alignItems="baseline"
            sx={{ cursor: 'pointer' }}
            onMouseEnter={({ currentTarget }) => {
              // setDropdown(currentTarget);
              setDropdownEl(currentTarget);
              setOpen(true);
            }}
            onMouseLeave={() => setOpen(false)}
          >
            <Grid container spacing={0} sx={{ marginTop: 2.5 }}>
              <Grid item>
                <Typography sx={{ color: 'black' }}>หมวดหมู่สินค้า</Typography>
              </Grid>
              <Grid item sx={{ marginTop: -0.5 }}>
                <ExpandMoreOutlinedIcon />
              </Grid>
            </Grid>
            <Menu
              sx={{ mt: '70px' }}
              id="menu-category"
              anchorEl={dropdownEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(open)}
              onClose={handleCloseCatMenu}
              PaperProps={{
                style: {
                  width: 350,
                  backgroundColor: '#85C1E9',
                },
              }}
            >
              {categories.map((cat, index) => (
                <MenuItem
                  onClick={handleCloseCatMenu}
                  component={Link}
                  to={{
                    pathname: `/dealer/products/category/${cat.category_id}`,
                  }}
                  key={index}
                >
                  <Typography textAlign="center">
                    {cat.category_name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            component={Link}
            to="/about-us"
            mx={1}
            p={3}
            display="flex"
            alignItems="baseline"
            sx={{ cursor: 'pointer' }}
            style={{ textDecoration: 'none' }}
            underline="none"
          >
            <Typography sx={{ color: 'black' }}>รู้จักเรา</Typography>
          </Box>
          <Box
            component={Link}
            to="/contact-us"
            mx={1}
            p={3}
            display="flex"
            alignItems="baseline"
            sx={{ cursor: 'pointer' }}
            style={{ textDecoration: 'none' }}
            underline="none"
          >
            <Typography sx={{ color: 'black' }}>ติดต่อเรา</Typography>
          </Box> */}
          <Box
            component={Link}
            to={{
              pathname: `/dealer/cart`,
            }}
            mx={1}
            p={3}
            display="flex"
            alignItems="baseline"
            sx={{ cursor: 'pointer' }}
            style={{ textDecoration: 'none' }}
            underline="none"
          >
            <Typography sx={{ color: 'black' }}>ตะกร้าสินค้า</Typography>
          </Box>

          <IconButton onClick={handleOpenUserMenu}>
            <Avatar
              alt={authUser === null ? '' : authUser.authEmail}
              src="/static/images/avatar/2.jpg"
            ></Avatar>
          </IconButton>
          <Menu
            sx={{ mt: '70px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem
              onClick={handleCloseUserMenu}
              component={Link}
              to={{
                pathname: `/dealer/order/history`,
              }}
            >
              <Typography>ประวัติการสั่งซื้อ</Typography>
            </MenuItem>
            <MenuItem
              onClick={handleCloseUserMenu}
              component={Link}
              to={{
                pathname: `/dealer/account`,
              }}
            >
              <Typography textAlign="center">บัญชี</Typography>
            </MenuItem>
            <MenuItem onClick={logout}>
              <Typography textAlign="center">ออกจากระบบ</Typography>
            </MenuItem>
          </Menu>
          {/* </Box> */}
        </>
      );
    } else {
      return (
        <>
          <Box
            component={Link}
            to="/home"
            mx={1}
            p={3}
            display="flex"
            alignItems="baseline"
            sx={{ cursor: 'pointer' }}
            style={{ textDecoration: 'none' }}
            underline="none"
          >
            <Typography sx={{ color: 'black' }}>หน้าแรก</Typography>
          </Box>
          <Box
            mx={1}
            p={3}
            display="flex"
            alignItems="baseline"
            sx={{ cursor: 'pointer' }}
            onMouseEnter={({ currentTarget }) => {
              // setDropdown(currentTarget);
              setDropdownEl(currentTarget);
              setOpen(true);
            }}
            onMouseLeave={() => setOpen(false)}
          >
            <Grid container spacing={0} sx={{ marginTop: 2.5 }}>
              <Grid item>
                <Typography sx={{ color: 'black' }}>หมวดหมู่สินค้า</Typography>
              </Grid>
              <Grid item sx={{ marginTop: -0.5 }}>
                <ExpandMoreOutlinedIcon />
              </Grid>
            </Grid>
            <Menu
              sx={{ mt: '70px' }}
              id="menu-category"
              anchorEl={dropdownEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(open)}
              onClose={handleCloseCatMenu}
              PaperProps={{
                style: {
                  width: 350,
                  backgroundColor: '#85C1E9',
                },
              }}
            >
              {categories.map((cat, index) => (
                <MenuItem
                  onClick={handleCloseCatMenu}
                  component={Link}
                  to={{ pathname: `/products/category/${cat.category_id}` }}
                  key={index}
                >
                  <Typography textAlign="center">
                    {cat.category_name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            component={Link}
            to="/about-us"
            mx={1}
            p={3}
            display="flex"
            alignItems="baseline"
            sx={{ cursor: 'pointer' }}
            style={{ textDecoration: 'none' }}
            underline="none"
          >
            <Typography sx={{ color: 'black' }}>รู้จักเรา</Typography>
          </Box>
          <Box
            component={Link}
            to="/dealer"
            mx={1}
            p={3}
            display="flex"
            alignItems="baseline"
            sx={{ cursor: 'pointer' }}
            style={{ textDecoration: 'none' }}
            underline="none"
          >
            <Typography sx={{ color: 'black' }}>ตัวแทนจำหน่าย</Typography>
          </Box>
          <Box
            component={Link}
            to="/contact-us"
            mx={1}
            p={3}
            display="flex"
            alignItems="baseline"
            sx={{ cursor: 'pointer' }}
            style={{ textDecoration: 'none' }}
            underline="none"
          >
            <Typography sx={{ color: 'black' }}>ติดต่อเรา</Typography>
          </Box>
		  <Box
            component={Link}
            to="/blogs"
            mx={1}
            p={3}
            display="flex"
            alignItems="baseline"
            sx={{ cursor: 'pointer' }}
            style={{ textDecoration: 'none' }}
            underline="none"
          >
            <Typography sx={{ color: 'black' }}>บทความ</Typography>
          </Box>
        </>
      );
    }
  };

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: '#85C1E9',
        width: '100%',
        borderRadius: 2,
        height: 60,
        display: { xs: 'none', md: 'flex' },
      }}
    >
      {userMenu()}
    </Box>
  );
};

export default Navbar;
