import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import DealerServices from '../../../services/dealerServices';
// import AuthenServices from '../../services/authenServices';
// import UserServices from '../../services/userServices';
import Iconify from '../../../components/Iconify';
import { store } from '../../../store';
import Moment from 'moment';
import AddNewAddress from './AddNewAddress';
import EditAddress from './EditAddress';

const Account = () => {
  const [address, setAddress] = useState('');
  const [currentUser, setCurrentUser] = useState([]);
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lasttName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [messageDialog, setMessageDialog] = useState('');
  const user = store.getState().authReducer.user;

  useEffect(() => {
    const getUser = async () => {
      await DealerServices.getDealerById(user.id).then((response) => {
        setCurrentUser(response);
        //console.log(response);
        setFirstName(response.first_name);
        setLastName(response.last_name);
        setEmail(response.email);
        setPhone(response.phone);
        setBirthdate(response.birthdate);
      });
      await DealerServices.getDealerAddress(user.id).then((res) => {
        // console.log('Address:', res);
        if (res.status === 200) {
          setAddress(res.data);
        } else {
          setAddress('');
        }
      });
    };
    getUser();
  }, [user.id]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleShowConfirmPass = () => {
    setShowConfirmPass((show) => !show);
  };
  const handleSubmitDetails = async () => {
    try {
      const data = {
        firstName: firstName,
        lastName: lasttName,
        email: email,
        phone: phone,
        birthdate: Moment(birthdate).format('YYYY-MM-DD').toString(),
      };

      await DealerServices.updateDealerDetail(user.id, data).then((res) => {
        if (res.status === 200) {
          setMessageDialog('อัพเดตข้อมูลส่วนตัวสำเร็จ');
          setOpenDialog(true);
        } else {
          setMessageDialog('อัพเดตข้อมูลส่วนตัวไม่สำเร็จ โปรดลองใหม่ภายหลัง');
          setOpenDialog(true);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPass) {
      setErrMsg('รหัสผ่านไม่ตรงกัน');
    } else {
      if (password.length < 6) {
        setErrMsg('รหัสผ่านต้องมีอย่างน้อย 6 ตัว');
      } else {
        setErrMsg('');
        const data = {
          password: password,
        };
        await DealerServices.updateDealerPassword(user.id, data).then((res) => {
          if (res.status === 200) {
            setMessageDialog('อัพเดตรหัสผ่านสำเร็จ');
            setOpenDialog(true);
            setPassword('');
            setConfirmPass('');
          } else {
            setMessageDialog('อัพเดตรหัสผ่านไม่สำเร็จ โปรดลองใหม่ภายหลัง');
            setOpenDialog(true);
            setPassword('');
            setConfirmPass('');
          }
        });
      }
    }
  };

  const renderAddress = () => {
    if (address === '') {
      return (
        <>
          <Box
            sx={{
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Typography marginTop={5} color="red">
              คุณยังไม่ได้เพิ่มที่อยู่
            </Typography>
          </Box>
        </>
      );
    } else {
      return (
        <>
          <Typography>
            {address.address1} {address.address2}
          </Typography>
        </>
      );
    }
  };

  const refreshAddress = async () => {
    await DealerServices.getDealerAddress(user.id).then((res) => {
      // console.log('Address:', res);
      if (res.status === 200) {
        setAddress(res.data);
      } else {
        setAddress('');
      }
    });
  };
  const addNewAddress = () => {};
  return (
    <Container>
      <Box maxWidth={750}>
        <form
          autoComplete="off"
          noValidate
          //   {...props}
        >
          <Card>
            <CardHeader title="ข้อมูลส่วนตัว" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="ชื่อ"
                    name="firstName"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="นามสกุล"
                    name="lastName"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lasttName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="อีเมล"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    disabled={true}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="เบอร์โทรศัพท์"
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="วันเกิด"
                    name="birthdate"
                    onChange={(e) => setBirthdate(e.target.value)}
                    value={Moment(birthdate).format('YYYY-MM-DD').toString()}
                    type="date"
                    InputProps={{
                      inputProps: { min: '1940-01-01' },
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography sx={{ color: 'green' }}>
                    ยอดซื้อสะสม: {currentUser.dealer_point}
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography sx={{ color: 'green' }}>
                    สถานะ: {currentUser.membership_status}
                  </Typography>
                </Grid>
				<Grid item md={12} xs={12}>
                  <Card
                    sx={{
                      color: '#000000',
                      backgroundColor: '#9FE2BF ',
                      padding: 2,
                    }}
                  >
                    <Typography>เงื่อนไขสมาชิก</Typography>
                    <Stack direction="column">
                      <Typography>
                        {'ยอดขายสะสม 0 - 10,000 บาท >> Silver'}
                      </Typography>
                      <Typography>
                        {'ยอดขายสะสม 10,001 - 20,000 บาท >> Gold'}
                      </Typography>
                      <Typography>
                        {'ยอดขายสะสม 20,001 - 30,000 บาท >> Platinum'}
                      </Typography>
                      <Typography>
                        {'ยอดขายสะสม 30,001 บาท ขึ้นไป >> Diamond'}
                      </Typography>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
            {/* <Divider /> */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2,
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmitDetails}
              >
                บันทึก
              </Button>
            </Box>
          </Card>
          <Card sx={{ mt: 3 }}>
            <Stack direction="row" justifyContent="space-between">
              <CardHeader title="ที่อยู่" />
              {address === '' ? (
                <AddNewAddress onRefresh={refreshAddress} />
              ) : (
                <EditAddress
                  userID={user.id}
                  data={address}
                  onRefresh={refreshAddress}
                />
              )}
              {/* <Button onClick={addNewAddress}>เพิ่มที่อยู่</Button> */}
            </Stack>
            <Divider />
            <CardContent>{renderAddress()}</CardContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2,
              }}
            >
              <Grid item md={6} xs={12}>
                <div>
                  <span style={{ color: 'red' }}>{errMsg}</span>
                </div>
              </Grid>

              {/* {address !== '' && (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={EditAddress}
                  >
                    แก้ไขที่อยู่
                  </Button>
                </>
              )} */}
            </Box>
            {/* <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2,
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmitDetails}
              >
                
              </Button>
            </Box> */}
          </Card>
          <Card sx={{ mt: 3 }}>
            <CardHeader title="รหัสผ่าน" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    label="รหัสผ่าน"
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Iconify
                              icon={
                                showPassword
                                  ? 'eva:eye-fill'
                                  : 'eva:eye-off-fill'
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    type={showConfirmPass ? 'text' : 'password'}
                    label="ยืนยันรหัสผ่าน"
                    onChange={(e) => setConfirmPass(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleShowConfirmPass}
                            edge="end"
                          >
                            <Iconify
                              icon={
                                showConfirmPass
                                  ? 'eva:eye-fill'
                                  : 'eva:eye-off-fill'
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>

            {/* <Divider /> */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2,
              }}
            >
              <Grid item md={6} xs={12}>
                <div>
                  <span style={{ color: 'red' }}>{errMsg}</span>
                </div>
              </Grid>

              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmitPassword}
              >
                ตั้งค่ารหัสผ่านใหม่
              </Button>
            </Box>
          </Card>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogTitle id="alert-success-dialog-title">สำเร็จ</DialogTitle> */}
            <DialogContent>
              <DialogContentText id="alert-success-dialog-description">
                {messageDialog}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleClose}>Disagree</Button> */}
              <Button onClick={handleCloseDialog} autoFocus>
                ตกลง
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </Box>
    </Container>
  );
};
export default Account;
