import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { IconX } from '@tabler/icons';
import NavMenuItem from './NavMenuItem';
import toysSpaceLogo from '../../../../assets/images/toys-logo.png';

const drawerWidth = '100%';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  //   justifyContent: 'flex-end',
}));

const NavDrawerMenu = ({ menu, openDrawer, handleDrawerClose }) => {
  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={openDrawer}
      >
        <DrawerHeader>
          <Box
            sx={{
              backgroundImage: ` url(${toysSpaceLogo})`,
              width: 50,
              height: 50,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              placeItems: 'center',
              marginTop: 3,
            }}
          ></Box>
          <Box flexGrow={1} />
          <IconButton onClick={handleDrawerClose}>
            <IconX />
            {/* {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )} */}
          </IconButton>
        </DrawerHeader>
        <Divider />

        {menu.map((m) => (
          <NavMenuItem key={m.title} item={m} onCloseMenu={handleDrawerClose} />
        ))}
      </Drawer>
    </>
  );
};
export default NavDrawerMenu;
