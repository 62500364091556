import PropTypes from 'prop-types';
// material
import { Box, Card, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  position: 'absolute',
});

// ----------------------------------------------------------------------

DealerProductCard.propTypes = {
  product: PropTypes.object,
};

export default function DealerProductCard({ product }) {
  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {/* {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            {status}
          </Label>
        )} */}
        <ProductImgStyle alt={product.alt} src={product.image_url} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        {/* <Link to="#" color="inherit" underline="hover" component={RouterLink}> */}
        <Typography variant="subtitle2" noWrap>
          {product.product_name}
        </Typography>
        {/* </Link> */}
        <hr />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {product.product_quantity <= 0 && (
            <Typography sx={{ color: 'red' }}>สินค้าหมด</Typography>
          )}
          <div></div>
          {/* <ColorPreview colors={colors} /> */}
          <Typography component="span" variant="body1" sx={{ color: 'red' }}>
            {/* variant="subtitle1" */}
            {/* {product.price + '฿'} */}
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through',
              }}
            >
              {product.price}
              {/* {priceSale && fCurrency(priceSale)} */}
            </Typography>
            &nbsp;
            {product.dealer_price}
            {/* {fCurrency(price)} */}
          </Typography>
        </Stack>
        {/* <Button variant="contained">ใส่ตะกร้า</Button> */}
      </Stack>
    </Card>
  );
}
