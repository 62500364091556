import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

import BlogServices from '../../services/blogServices';
import BlogPostCard from '../../components/BlogPostCard';

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    const getPosts = async () => {
      await BlogServices.getAllBlogs().then((res) => {
        // console.log('All posts:', res);
        if (res.data.length > 0) {
          setNoData(false);
          setPosts(res.data);
        } else {
          setNoData(true);
        }
      });
    };
    getPosts();
  }, []);
  return (
    <Box
      sx={{
        // marginTop: '10px',
        backgroundColor: 'white',
        borderRadius: '10px',
      }}
    >
      <Container sx={{ alignItems: 'center', paddingBottom: '50px' }}>
        <Grid container spacing={3}>
          {posts?.map((post, index) => (
            <BlogPostCard key={post.blog_id} post={post} index={index} />
          ))}
        </Grid>
        {noData && (
          <>
            <Typography>ยังไม่มีบทความในขณะนี้</Typography>
          </>
        )}
      </Container>
    </Box>
  );
};
export default Blog;
