import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/images/toys-logo.png';
const theme = createTheme();
const RegisterSuccess = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid>
            <Box
              sx={{
                backgroundImage: ` url(${Logo})`,
                width: 250,
                height: 250,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                placeItems: 'center',
              }}
            ></Box>
          </Grid>
          <Grid>
            <Typography variant="h5" color="green">
              สมัครเป็นตัวแทนจำหน่ายสำเร็จ!
            </Typography>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={12} sm={6}>
              <Button fullWidth variant="contained" component={Link} to={'/'}>
                กลับสู่หน้าหลัก
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="contained"
                component={Link}
                to={'/dealer/login'}
              >
                เข้าสู่ระบบ
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
export default RegisterSuccess;
