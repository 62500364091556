import { Box, Grid, Paper, Typography } from '@mui/material';
import cartoon from '../../assets/images/toys-cartoon.png';
import { Container } from '@mui/system';
import aboutUsBg from '../../assets/images/bg-about-us.png';
const textColor = '#6E2C00';

const AboutUs = () => {
  return (
    // <Box>
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // justifyContent: 'center',
        minHeight: { xs: '120vh', md: '120vh' },
        width: '100%',
        backgroundImage: ` url(${aboutUsBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Typography variant="h3" color={textColor} sx={{ marginTop: 10 }}>
        Toy's Space
      </Typography>
      <Grid container spacing={2} sx={{ marginLeft: 5, marginRight: 5 }}>
        <Grid item xs={12} sm={6}>
          <Box>
            <Paper
              sx={{
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 5,
                paddingBottom: 5,
                marginTop: 2,
              }}
            >
              <Paper
                sx={{
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  // marginTop: '50px',
                }}
              >
                <Typography variant="h5" color={textColor}>
                  ทอยสเปซ เป็นร้านจำหน่ายสื่อการเรียนการสอน
                  และของเล่นเสริมพัฒนาการเด็ก เปิดดำเนินการตั้งแต่ปี 2562
                  มีช่องทางจำหน่ายทั้งทางหน้าร้าน และออนไลน์
                </Typography>
              </Paper>
              <Paper
                sx={{
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  marginTop: '50px',
                }}
              >
                <Typography variant="h5" color={textColor}>
                  สินค้าทุกชิ้นของทอยสเปซผ่านการเลือกสรรอย่างพิถีพิถัน
                  โดยคำนึงถึงคุณภาพและความปลอดภัยสูงสุดต่อผู้ใช้งาน
                  เป็นสื่อการเรียนการสอน
                  และของเล่นที่จะช่วยส่งเสริมการพัฒนาทักษะด้านความคิด อารมณ์
                  ความจำ รวมถึงส่งเสริมการพัฒนาของกล้ามเนื้อมัดต่าง ๆ
                  ผ่านการเล่น การรับรู้และจินตนาการของเด็ก
                </Typography>
              </Paper>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <Box
            sx={{
              width: '567px',
              minHeight: { xs: '30vh', md: '70vh' },
              backgroundImage: ` url(${cartoonBg})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
          > */}
          <Box
            sx={{
              width: '567px',
              minHeight: { xs: '30vh', md: '70vh' },
              backgroundImage: ` url(${cartoon})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
          ></Box>
          {/* </Box> */}
          {/* <img src={cartoon} alt=""></img> */}

          {/* <Item>2</Item> */}
        </Grid>
      </Grid>
    </Container>
  );
};
export default AboutUs;
