import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import ProductServices from '../../services/productServices';
import ProductList from '../../components/ProductList';

const Home = () => {
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      const response = await ProductServices.getAllProducts();
      //   console.log(response);
      setAllProducts(response);
    };
    getProducts();
  }, []);

  return (
    <Box
      sx={{
        // marginTop: '10px',
        backgroundColor: 'white',
        borderRadius: '10px',
      }}
    >
      <Container sx={{ alignItems: 'center', paddingBottom: '50px' }}>
        <ProductList products={allProducts} />
      </Container>
    </Box>
  );
};

export default Home;
