import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import AuthenServices from '../services/authenServices';
import { store } from '../store';

const AuthGuard = ({ children }) => {
  const isSignedIn = store.getState().authReducer.isSignedIn;

  const navigate = useNavigate();

  useEffect(() => {
    //console.log('isLoggedIn Auth', isSignedIn);
    if (!isSignedIn) {
      navigate('/home', { replace: true });
    }
  }, [isSignedIn, navigate]);

  return children;
};

export default AuthGuard;
