import http from '../http-common';
class CategoryServices {
  createCategory = async (category) => {
    try {
      await http.post('/categories', category);
    } catch (err) {
      console.log(err);
    }
  };
  getAllCategories = async () => {
    try {
      const response = await http.get('/categories');
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  getCategoryById = async (catID) => {
    try {
      const response = await http.get(`/categories/${catID}`);
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  updateById = async (catID, newCategory) => {
    try {
      const data = {
        category_name: newCategory,
      };
      const response = await http.put(`/categories/${catID}`, data);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
}

export default new CategoryServices();
