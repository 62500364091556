import PropTypes from 'prop-types';
// material
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import createTypography from 'material-ui/styles/typography';

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

export default function ThemeConfig({ children }) {
  // const typography = createTypography(palette, {
  //   fontFamily: '"Comic Sans"',
  // });
  const toysSpaceTheme = createTheme({
    palette: {
      background: {
        default: '#ffffff',
        // default: '#b0cee1',
        // default: '#A2DCF2',
      },

      //   text: {
      //     primary: '#ffffff',
      //   },
    },
    typography: {
      fontFamily: `'Mitr', sans-serif`,
    },
  });

  const theme = createTheme(toysSpaceTheme);
  //   theme.components = componentsOverride(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

// import PropTypes from 'prop-types';
// import { useMemo } from 'react';
// // material
// import { CssBaseline } from '@mui/material';
// import {
//   ThemeProvider,
//   createTheme,
//   StyledEngineProvider,
// } from '@mui/material/styles';
// //
// import palette from './palette';
// import typography from './typography';
// import componentsOverride from './overrides';
// import shadows, { customShadows } from './shadows';

// // ----------------------------------------------------------------------

// ThemeConfig.propTypes = {
//   children: PropTypes.node,
// };

// export default function ThemeConfig({ children }) {
//   const themeOptions = useMemo(
//     () => ({
//       palette: {
//       },
//       shape: { borderRadius: 8 },
//       typography,
//       shadows,
//       customShadows,
//     }),
//     []
//   );

//   const theme = createTheme(themeOptions);
//   theme.components = componentsOverride(theme);

//   return (
//     <StyledEngineProvider injectFirst>
//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         {children}
//       </ThemeProvider>
//     </StyledEngineProvider>
//   );
// }
