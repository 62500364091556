import MainLayout from '../layout/MainLayout';
import DealerProducts from '../pages/Dealer/Products';
import DealerCategory from '../pages/Dealer/Products/Category/_id';
import DealerProductDetails from '../pages/Dealer/Products/_id';
import DealerCart from '../pages/Dealer/Cart';
import OrderDetails from '../pages/Dealer/Order';
import Account from '../pages/Dealer/Account';

//Router that no need guard
import AboutUs from '../pages/AboutUs';
import ContactUs from '../pages/ContactUs';
import AuthGuard from '../utils/AuthGuard';
import OrderHistory from '../pages/Dealer/Order/History';
// import InvoiceDetails from '../pages/Dealer/Invoice/_id';
import OrderSuccess from '../pages/Dealer/Order/success';
import Blog from '../pages/Blog';
import Post from '../pages/Blog/_id';

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      // element: <MainLayout />,
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/dealer/home',
          element: <DealerProducts />,
        },
        {
          path: 'dealer/products/category/:id',
          element: <DealerCategory />,
        },
        {
          path: 'dealer/products/:id',
          element: <DealerProductDetails />,
        },
        {
          path: 'dealer/cart',
          element: <DealerCart />,
        },
        {
          path: 'dealer/order/:id',
          element: <OrderDetails />,
        },
        {
          path: '/dealer/order/history',
          element: <OrderHistory />,
        },
        {
          path: '/dealer/account',
          element: <Account />,
        },
        {
          path: '/dealer/order/success/:dealerId/:orderId',
          element: <OrderSuccess />,
        },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: 'about-us',
          element: <AboutUs />,
        },
        {
          path: 'contact-us',
          element: <ContactUs />,
        },
	    { path: 'blogs', element: <Blog /> },
        { path: 'blogs/:id', element: <Post /> },
      ],
    },
  ],
};

export default MainRoutes;
