import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Button,
  TextField,
} from '@mui/material';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import ProductServices from '../../../services/productServices';
import DealerServices from '../../../services/dealerServices';
import { blockInvalidNumber } from '../../../components/BlockInvalidNumber';
import { store } from '../../../store';

const DealerProductDetails = () => {
  const { id } = useParams();
  const [dealerCartItems, setDealerCartItems] = useState([]);
  const [productInfo, setProductInfo] = useState([]);
  const [allPhotos, setAllPhotos] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [arrow, setArrow] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);
  // const [outOfStock, setOutOfStock] = useState(false)
  const user = store.getState().authReducer.user;
  useEffect(() => {
    const getProductDetails = async () => {
      await ProductServices.getProductInfo(id).then((res) => {
        console.log('Detail', res);
        if (res.product_quantity === 0 || res.product_quantity < 0) {
          setDisableAdd(true);
        }
        setProductInfo(res);
      });

      await ProductServices.getProductPictures(id).then((res) => {
        setAllPhotos(res);
        if (res.length > 1) {
          setArrow(true);
        }
      });
    };
    const getCart = async () => {
      await DealerServices.getItemsInDealerCart(user.id).then((res) => {
        const cartItem = res.filter((p) => p.product_id === Number(id));
        if (cartItem.length > 0) {
          setDealerCartItems(cartItem[0]);
        } else {
          setDealerCartItems(null);
        }
      });
    };
    getCart();
    getProductDetails();
  }, [id, user.id]);
  const fadeProperties = {
    infinite: false,
    indicators: true,
    autoplay: false,
    arrows: arrow,
  };
  const handleChangeQuantity = (e) => {
    setDisableAdd(false);
    let q = Number(e.target.value);
    setQuantity(q);
    if (q === ' ' || q === 0) {
      setDisableAdd(true);
    } else {
      if (dealerCartItems !== null) {
        const calQuantity =
          Number(dealerCartItems.quantity) + Number(e.target.value);
        if (calQuantity > productInfo.product_quantity) {
          alert(
            'เหลือสินค้าเพียง ' +
              productInfo.product_quantity +
              ' ชิ้น' +
              `\n` +
              'คุณมีสินค้าในตะกร้าแล้ว ' +
              dealerCartItems.quantity +
              ' ชิ้น'
          );
          setQuantity(
            Number(productInfo.product_quantity) -
              Number(dealerCartItems.quantity)
          );
        } else {
          setQuantity(q);
        }
      } else {
        if (q > productInfo.product_quantity) {
          alert('เหลือสินค้าเพียง ' + productInfo.product_quantity + ' ชิ้น');
          setQuantity(Number(productInfo.product_quantity));
        } else {
          setQuantity(q);
        }
      }
    }
  };
  const handleSubmit = async () => {
    const dealer = JSON.parse(localStorage.getItem('toysSpaceDealer'));
    const data = {
      dealer_id: dealer.id,
      product_id: id,
      quantity: quantity,
    };
    const response = await DealerServices.addItemToCart(data);
    if (response.dealer_cart_item_id !== undefined) {
      window.location = `/dealer/cart`;
    }
  };

  return (
    <Container display="flex">
      <Box
        position="relative"
        component="header"
        width="100%"
        height="450px"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <div className="slide-container">
              <Fade {...fadeProperties}>
                {allPhotos.map((slideImage, index) => (
                  <div className="slide" key={index}>
                    <div
                      style={{
                        backgroundImage: `url(${slideImage.image_url})`,
                      }}
                    ></div>
                  </div>
                ))}
              </Fade>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: { md: '150px' },
                marginLeft: { md: '100px', sm: '50px' },
              }}
            >
              <List sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <ListItem>ชื่อสินค้า: {productInfo.product_name}</ListItem>
                <ListItem>ประเภท: {productInfo.category_name}</ListItem>
                <ListItem>
                  รายละเอียด: {productInfo.product_description}
                </ListItem>
                <ListItem sx={{ color: 'red' }}>
                  ราคาตัวแทน: {productInfo.dealer_price} บาท
                </ListItem>
                <ListItem>
                  <TextField
                    value={quantity}
                    onKeyDown={blockInvalidNumber}
                    onChange={(e) => handleChangeQuantity(e)}
                  ></TextField>
                </ListItem>
                <ListItem>
                  <Button
                    variant="contained"
                    disabled={disableAdd}
                    onClick={handleSubmit}
                  >
                    เพิ่มสินค้าในตะกร้า
                  </Button>
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default DealerProductDetails;
