import React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
  Stack,
} from '@mui/material';
import { Link } from 'react-router-dom';

import DealerServices from '../../../services/dealerServices';
import { IconPlus, IconMinus } from '@tabler/icons';
import WarningDialog from '../../../components/WarningDialog';
import { store } from '../../../store';

const DealerCart = () => {
  const [dealerCartItems, setDealerCartItems] = useState([]);
  const [quantity, setQuantity] = useState('');
  const [editItem, setEditItem] = useState();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [saveEditButton, setSaveEditButton] = useState(false);
  const user = store.getState().authReducer.user;
  useEffect(() => {
    const getCart = async () => {
      await DealerServices.getItemsInDealerCart(user.id).then((res) => {
        setDealerCartItems(res);
      });
    };
    getCart();
  }, [user.id]);

  const handleUpdateQuantity = async () => {
    console.log('q', quantity);
    console.log('id', editItem.dealer_cart_item_id);
    const data = {
      quantity: quantity,
    };
    try {
      await DealerServices.updateItem(editItem.dealer_cart_item_id, data).then(
        (res) => {
          setOpenEditDialog(false);
        }
      );
      await DealerServices.getItemsInDealerCart(user.id).then((res) => {
        setDealerCartItems(res);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const increaseItem = async (itemId) => {
    try {
      await DealerServices.increaseItem(itemId);
      await DealerServices.getItemsInDealerCart(user.id).then((res) => {
        setDealerCartItems(res);
      });
    } catch (err) {
      console.log(err);
    }
  };
  const decreaseItem = async (itemId) => {
    try {
      await DealerServices.decreaseItem(itemId);
      await DealerServices.getItemsInDealerCart(user.id).then((res) => {
        setDealerCartItems(res);
      });
    } catch (err) {
      console.log(err);
    }
  };
  const deleteItem = async (itemId) => {
    try {
      await DealerServices.deleteItem(itemId);
      await DealerServices.getItemsInDealerCart(user.id).then((res) => {
        setDealerCartItems(res);
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleEditItemQuantity = (e) => {
    if (e.target.value === '') {
      setSaveEditButton(true);
      setQuantity(e.target.value);
    } else {
      setSaveEditButton(false);
      setQuantity(e.target.value);
    }
  };

  const handleEditItem = async (item) => {
    // console.log(item);
    setEditItem(item);
    setQuantity(item.quantity);
    setOpenEditDialog(true);
  };

  const handleSubmitOrder = () => {
    const filter = dealerCartItems.filter(
      (p) => p.quantity > p.product_quantity
    );
    if (filter.length > 0) {
      setOpenDialog(true);
    } else {
      window.location = `/dealer/order/${user.id}`;
    }
  };

  const cartItems = () => {
    if (dealerCartItems.length > 0) {
      return (
        <>
          {dealerCartItems.map((i) => (
            <List key={i.dealer_cart_item_id}>
              <ListItem
                secondaryAction={
                  <Stack direction="row">
                    <Button onClick={() => handleEditItem(i)}> แก้ไข</Button>
                    <WarningDialog
                      title="ต้องการลบสินค้านี้ออกจากตะกร้า ?"
                      message=""
                      data={i.product_name}
                      button="ลบ"
                      onWarningSubmit={() => deleteItem(i.dealer_cart_item_id)}
                    />
                  </Stack>
                }
                // secondaryAction={
                //   <>
                //     <EditProductQuantityDialog item={i} />
                //   </>
                // }
                sx={{ margin: 3 }}
              ></ListItem>
              <ListItem disabled={i.quantity > i.product_quantity}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item>
                    <div className="cart-item">
                      <img src={i.image_url} alt={i.product_name} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs sx={{ marginTop: 4, marginLeft: 4 }}>
                        <Typography
                          gutterBottom
                          variant="subtitle1"
                          component="div"
                        >
                          {i.product_name}
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="subtitle1"
                          component="div"
                        >
                          ราคา/ชิ้น
                          {' ' + i.dealer_price + ' '} บาท
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container justifyContent="center">
                          <Grid item>
                            <IconButton
                              sx={{
                                borderRadius: 4,
                                border: 2,
                                marginTop: 1,
                              }}
                              onClick={() =>
                                decreaseItem(i.dealer_cart_item_id)
                              }
                              disabled={
                                i.quantity <= 1 ||
                                i.quantity > i.product_quantity
                              }
                            >
                              <IconMinus />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <TextField
                              value={i.quantity}
                              sx={{
                                marginLeft: 2,
                                marginRight: 2,
                                width: 80,
                              }}
                              InputProps={{
                                sx: {
                                  '& input': {
                                    textAlign: 'center',
                                  },
                                },
                              }}
                            ></TextField>
                          </Grid>
                          <Grid item>
                            <IconButton
                              value={i.dealer_cart_item_id}
                              sx={{
                                borderRadius: 4,
                                border: 2,
                                marginTop: 1,
                              }}
                              onClick={() =>
                                increaseItem(i.dealer_cart_item_id)
                              }
                              disabled={i.quantity >= i.product_quantity}
                            >
                              <IconPlus />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item sx={{ marginTop: 2, marginRight: 4 }}>
                      <WarningDialog
                        title="ต้องการลบสินค้านี้ออกจากตะกร้า ?"
                        message=""
                        data={i.product_name}
                        button="ลบ"
                        onWarningSubmit={() =>
                          deleteItem(i.dealer_cart_item_id)
                        }
                      />
                    </Grid> */}
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem alignItems="center">
                <Box display="flex" justifyContent="center">
                  {i.quantity > i.product_quantity ||
                  i.product_quantity < 10 ? (
                    <Typography color="red">
                      เหลือสินค้าเพียง {i.product_quantity} ชิ้น
                    </Typography>
                  ) : null}
                </Box>
              </ListItem>
              <Divider sx={{ marginTop: 4 }} />
            </List>
          ))}
          <Grid container justifyContent="center" sx={{ marginTop: 4 }}>
			<Grid item sx={{ marginRight: 4 }}>
              <Button variant="outlined" component={Link} to={`/dealer/home`}>
                ซื้อสินค้าเพิ่ม
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={handleSubmitOrder}
                // component={Link}
                // to={`/dealer/order/${user.id}`}
              >
                สั่งซื้อสินค้า
              </Button>
            </Grid>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Card sx={{ height: '100px' }}>
            <Box
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Typography marginTop={5}>คุณยังไม่มีสินค้าในตะกร้า</Typography>
            </Box>
          </Card>
          <Grid container justifyContent="center" sx={{ marginTop: 4 }}>
            <Grid item>
              <Button variant="outlined" component={Link} to={`/dealer/home`}>
                เลือกซื้อสินค้า
              </Button>
            </Grid>
          </Grid>
        </>
      );
    }
  };
  return (
    <Container>
      <Box justifyContent="center">
        <Typography variant="h5">ตะกร้าสินค้าของคุณ</Typography>
        {cartItems()}
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>รายการสินค้าที่คุณเลือกไม่สามารทำรายการได้</DialogTitle>
        <DialogContent>กรุณาแก้ไขรายการสินค้า</DialogContent>
        <DialogActions>
          {' '}
          <Button variant="contained" onClick={handleCloseDialog}>
            ตกลง
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>แก้ไขจำนวนสินค้า</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="color"
            label="จำนวน"
            type="text"
            fullWidth
            variant="standard"
            value={quantity}
            // onChange={(event) => setQuantity(event.target.value)}
            onChange={(event) => handleEditItemQuantity(event)}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseEditDialog} variant="outlined">
            ยกเลิก
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateQuantity}
            disabled={saveEditButton}
          >
            บันทึก
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
export default DealerCart;
