import { useEffect, useState } from 'react';
import React from 'react';
// import { Fade } from 'react-slideshow-image';

// material
import { Container, Stack, Typography } from '@mui/material';
// components
import DealerProductList from '../../../components/Dealer/DealerProductList';

//Service
import ProductSevices from '../../../services/productServices';

// --------------------------------Products for dealer--------------------------------------

export default function DealerProducts() {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const getProducts = async () => {
      const response = await ProductSevices.getAllProducts();
      console.log('All product:', response);
      setProducts(response);
    };
    getProducts();
  }, []);
  //   const fadeProperties = {
  //     infinite: false,
  //     indicators: true,
  //     autoplay: false,
  //   };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" sx={{ mb: 5 }}>
          สินค้าทั้งหมด
        </Typography>
      </Stack>
      <DealerProductList products={products} />
      {/* <div className="slide-container">
        <Fade {...fadeProperties}>
          {products.map((p, index) => (
            <div className="slide" key={index}>
              <div
                style={{
                  backgroundImage: `url(${p.image_url})`,
                }}
              ></div>
            </div>
          ))}
        </Fade>
      </div> */}
    </Container>
  );
}
