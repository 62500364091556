export const blockInvalidNumber = (e) =>
  // {
  //   const re = /^[a-zA-Z]/;
  //   if (e.key.match(re)) {
  //     e.preventDefault();
  //   }
  // };
  // ['+', '-'].includes(e.key) && e.preventDefault();
  [
    'a',
    'A',
    'b',
    'B',
    'c',
    'C',
    'd',
    'D',
    'e',
    'E',
    'f',
    'F',
    'g',
    'G',
    'h',
    'H',
    'i',
    'I',
    'j',
    'J',
    'k',
    'K',
    'l',
    'L',
    'm',
    'M',
    'n',
    'N',
    'o',
    'O',
    'p',
    'P',
    'q',
    'Q',
    'r',
    'R',
    's',
    'S',
    't',
    'T',
    'u',
    'U',
    'v',
    'V',
    'w',
    'W',
    'x',
    'X',
    'y',
    'Y',
    'z',
    'Z',
    '!',
    '@',
    '#',
    '$',
    '%',
    '@',
    '^',
    '&',
    '*',
    '(',
    ')',
    '_',
    '=',
    '?',
    '|',
    '+',
    '-',
    '>',
    '<',
    '"',
    ':',
    ';',
    ',',
    `'`,
    '[',
    ']',
    '.',
    '{',
    '}',
    '/',
    '~',
    '`',
    'ๅ',
    '๑',
    '๒',
    '๓',
    '๔',
    '๕',
    '๖',
    '๗',
    '๘',
    '๙',
    '๐',
    'ภ',
    'ถ',
    'ุ',
    'ู',
    'ึ',
    '฿',
    'ค',
    'ต',
    'จ',
    'ข',
    'ช',
    'ๆ',
    'ไ',
    'ำ',
    'ฎ',
    'พ',
    'ฑ',
    'ะ',
    'ธ',
    'ั',
    'ํ',
    'ี',
    '๊',
    'ร',
    'ณ',
    'น',
    'ฯ',
    'ย',
    'ญ',
    'บ',
    'ฐ',
    'ล',
    ',',
    'ฟ',
    'ฤ',
    'ห',
    'ฆ',
    'ก',
    'ฏ',
    'ด',
    'โ',
    'เ',
    'ฌ',
    '้',
    '็',
    '่',
    '๋',
    'า',
    'ษ',
    'ส',
    'ศ',
    'ว',
    'ซ',
    'ง',
    '.',
    'ผ',
    '(',
    'ป',
    ')',
    'แ',
    'ฉ',
    'อ',
    'ฮ',
    'ิ',
    'ฺ',
    'ื',
    '์',
    'ท',
    '?',
    'ม',
    'ฒ',
    'ใ',
    'ฬ',
    'ฝ',
    'ฦ',
  ].includes(e.key) && e.preventDefault();
