import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
// import { Link , useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
// material
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// component
import Iconify from '../../../components/Iconify';

import AuthServices from '../../../services/authenServices';
import { LOGIN } from '../../../store/actions/types';
import { useDispatch } from 'react-redux';

// ----------------------------------------------------------------------
const theme = createTheme();

export default function LoginForm() {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const formRef = useRef();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('จำเป็นต้องกรอกชื่อผู้ใช้'),
    password: Yup.string().required('จำเป็นต้องกรอกรหัสผ่าน'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      try {
        const username = formik.values.email;
        const password = formik.values.password;

        const response = await AuthServices.login(username, password);
        if (response.token === undefined) {
          setErrMsg(response.status);
        } else {
          dispatch({
            type: LOGIN,
            payload: response,
          });
          window.location = '/dealer/home';
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  const { errors, touched, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            เข้าสู่ระบบ( สำหรับตัวแทนจำหน่าย )
          </Typography>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="อีเมล"
              name="email"
              autoComplete="email"
              autoFocus
              ref={formRef}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="รหัสผ่าน"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify
                        icon={
                          showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              เข้าสู่ระบบ
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  ลืมรหัสผ่าน?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/dealer/register" variant="body2">
                  {'ยังไม่ได้สมัครเป็นตัวแทนจำหน่าย? สมัคร'}
                </Link>
              </Grid>
            </Grid>
            <Grid sx={{ mt: 2 }}>
              <span style={{ color: 'red' }}>{errMsg}</span>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

// import * as React from 'react';
// import * as Yup from 'yup';
// import { useState } from 'react';
// // import { Link , useNavigate } from 'react-router-dom';
// import { useFormik, Form, FormikProvider } from 'formik';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// // import FormControlLabel from '@mui/material/FormControlLabel';
// // import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import AuthServices from '../../../services/authenServices';

// // function Copyright(props) {
// //   return (
// //     <Typography
// //       variant="body2"
// //       color="text.secondary"
// //       align="center"
// //       {...props}
// //     >
// //       {'Copyright © '}
// //       <Link color="inherit" href="https://mui.com/">
// //         Toy's space
// //       </Link>{' '}
// //       {new Date().getFullYear()}
// //       {'.'}
// //     </Typography>
// //   );
// // }

// const theme = createTheme();

// export default function Login() {
//   const [errMsg, setErrMsg] = useState('');

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     try {
//       const data = new FormData(event.currentTarget);
//       console.log('input data:', data);
//       const response = await AuthServices.login(data);
//       if (response.id === undefined) {
//         setErrMsg(response.status);
//       }
//     } catch (err) {
//       console.log(err);
//     }
//     // const data = new FormData(event.currentTarget);
//     // console.log({
//     //   email: data.get('email'),
//     //   password: data.get('password'),
//     // });
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Container component="main" maxWidth="xs">
//         <CssBaseline />
//         <Box
//           sx={{
//             marginTop: 8,
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//           }}
//         >
//           <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
//             <LockOutlinedIcon />
//           </Avatar>
//           <Typography component="h1" variant="h5">
//             เข้าสู่ระบบ( สำหรับตัวแทนจำหน่าย )
//           </Typography>
//           <Box
//             component="form"
//             onSubmit={handleSubmit}
//             noValidate
//             sx={{ mt: 1 }}
//           >
//             <TextField
//               margin="normal"
//               required
//               fullWidth
//               id="email"
//               label="อีเมล"
//               name="email"
//               autoComplete="email"
//               autoFocus
//             />
//             <TextField
//               margin="normal"
//               required
//               fullWidth
//               name="password"
//               label="รหัสผ่าน"
//               type="password"
//               id="password"
//               autoComplete="current-password"
//             />
//             {/* <FormControlLabel
//               control={<Checkbox value="remember" color="primary" />}
//               label="จำฉันไว้ในระบบ"
//             /> */}
//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               sx={{ mt: 3, mb: 2 }}
//             >
//               เข้าสู่ระบบ
//             </Button>
//             <Grid container>
//               <Grid item xs>
//                 <Link href="#" variant="body2">
//                   ลืมรหัสผ่าน?
//                 </Link>
//               </Grid>
//               <Grid item>
//                 <Link href="/dealer/register" variant="body2">
//                   {'ยังไม่ได้สมัครเป็นตัวแทนจำหน่าย? สมัคร'}
//                 </Link>
//               </Grid>
//             </Grid>
//             <Grid sx={{ mt: 2 }}>
//               <span style={{ color: 'red' }}>{errMsg}</span>
//             </Grid>
//           </Box>
//         </Box>
//         {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
//       </Container>
//     </ThemeProvider>
//   );
// }
