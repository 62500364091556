import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import footerBg from '../../../assets/images/footer-bg.png';
import { Grid, Icon } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

import LineBrandIcon from '../../../assets/LINE_Brand_icon.png';

const LineIcon = styled(Icon)(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',

  backgroundImage: ` url(${LineBrandIcon})`,
}));
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      TOY'S SPACE
      {'   '}
      {new Date().getFullYear()} 
	  {'   '}
	  {'All Rights Reserved.'}
    </Typography>
  );
}

export default function StickyFooter() {
  return (
    <Box
      component="footer"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: { xs: '30vh', md: '30vh' },
        backgroundImage: ` url(${footerBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        // minHeight: '100vh',
      }}
    >
      <CssBaseline />

      <Grid
        container
        spacing={4}
        columns={{ xs: 12, sm: 12, md: 12 }}
        sx={{ mt: { md: 15, xs: 'auto' } }}
      >
        <Grid item xs={12} sm={4} md={4}>
          <Box
            display="flex"
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <Button
              target="_blank"
              href="https://www.facebook.com/toysspace"
              style={{ textTransform: 'none', color: 'black' }}
            >
              <FacebookIcon />
              <Typography textAlign="left">toysspace</Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Box
            display="flex"
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <Button
              target="_blank"
              href="https://instagram.com/hi.toysspace"
              style={{ textTransform: 'none', color: 'black' }}
            >
              <InstagramIcon />
              <Typography textAlign="left">hi.toysspace</Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Box
            display="flex"
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <Button
              target="_blank"
              href="https://lin.ee/0qZRMkY"
              style={{ textTransform: 'none', color: 'black' }}
            >
              <LineIcon />
              <Typography textAlign="left"> @toysspace</Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          py: 3,
          mt: 'auto',
        }}
      >
        <Container maxWidth="md">
          <Copyright />
        </Container>
      </Box>
    </Box>
  );
}
