import http from '../http-common';

class AuthService {
  async login(email, password) {
    //console.log('Login user', username, password);
    try {
      const response = await http.post('/dealer/auth', {
        email: email,
        password: password,
      });
      console.log('login response:', response);
      if (response.data.token) {
        const user = JSON.stringify(response.data);
        localStorage.setItem('toysSpaceDealer', user);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  logout() {
    localStorage.removeItem('toysSpaceDealer');
    window.location = '/';
  }
  async register(username, password) {
    await http.post('/register', {
      username: username,
      password: password,
    });
  }

  async getCurrentUser() {
    try {
      const dealer = JSON.parse(localStorage.getItem('toysSpaceDealer'));
      if (dealer) {
        const currentUser = await http.get(`/dealer/${dealer.id}`);
        return currentUser.data;
      } else {
        localStorage.removeItem('toysSpaceDealer');
        return false;
      }
    } catch (err) {
      console.log(err);
    }

    // await liff.init({ liffId: '1656911262-me8ZZQ2B' }).catch(err=>{throw err});
    // try{
    //     if (liff.isLoggedIn()){
    //         const lineUser = await liff.getProfile();
    //       return lineUser
    //     }
    //     else {
    //         const user = JSON.parse(localStorage.getItem('user'));
    //           if (user) {
    //           const currentUser = await http.get(`/users/${user.id}`);
    //           return currentUser.data;
    //         } else {
    //           localStorage.removeItem('user');
    //           return false;
    //         }
    //     }
    // }catch (err) {
    //     console.log(err)
    // }
  }
  // async isAdmin() {
  //   const user = await this.getCurrentUser();
  //   if (user.role !== 'admin') {
  //     window.location = '/';
  //   }
  // }
  async isLoggedIn() {
    try {
      const dealer = JSON.parse(localStorage.getItem('toysSpaceDealer'));
      if (dealer) {
        const currentUser = await http.get(`/dealers/${dealer.id}`);
        if (currentUser) {
          return true;
        }
      } else {
        localStorage.removeItem('toysSpaceDealer');
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new AuthService();
