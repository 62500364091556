import http from '../http-common';
class BlogServices {
  getAllBlogs = async () => {
    try {
      const response = await http.get('/blog');
      return response;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  getBlogById = async (blogId) => {
    try {
      const response = await http.get(`/blog/${blogId}`);
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
}

export default new BlogServices();
