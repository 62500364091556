import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blockInvalidNumber } from '../../../components/BlockInvalidNumber';
import { blockInvalidText } from '../../../components/BlockInvalidText';
import Iconify from '../../../components/Iconify';
import Moment from 'moment';
import DealerServices from '../../../services/dealerServices';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const theme = createTheme();

export default function DealerRegister() {
  const formRef = React.useRef();
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [birthdate, setBirthdate] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassWord] = React.useState('');
  const [confirmPassErr, setConfirmPassErr] = React.useState('');
  const [confirmPass, setConfirmPass] = React.useState('');
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [confirmPassShown, setConfirmPassShown] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState('');

  const handleChangePass = (e) => {
    try {
      const pass = e.target.value;
      if (confirmPass !== '' || confirmPass.length > 0) {
        if (pass === confirmPass) {
          setConfirmPassErr('');
        } else {
          setConfirmPassErr('รหัสผ่านไม่ตรงกัน');
        }
      }
      setPassWord(pass);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeConfirmPass = (e) => {
    const confirmPass = e.target.value;
    setConfirmPass(confirmPass);
    if (password !== '' || password !== undefined) {
      if (password === confirmPass) {
        setConfirmPassErr('');
      } else {
        setConfirmPassErr('รหัสผ่านไม่ตรงกัน');
      }
    }
  };
  const handleShowPassword = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const handleShowConfirmPassword = () => {
    setConfirmPassShown(confirmPassShown ? false : true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrMsg('');
    setOpenBackdrop(true);
    try {
      if (confirmPassErr.length === 0) {
        if (password.length < 6) {
          setOpenBackdrop(false);
          setConfirmPassErr('รหัสผ่านต้องมีอย่างน้อย 6 ตัว');
        } else {
          setConfirmPassErr('');
          const data = {
            first_name: firstName,
            last_name: lastName,
            birthdate: Moment(birthdate).format('YYYY-MM-DD').toString(),
            phone: phone,
            email: email,
            password: password,
            confirmPassword: confirmPass,
          };
          //   console.log('data:', data);
          const response = await DealerServices.createDealer(data);
          if (response.dealer_id !== undefined) {
            setOpenBackdrop(false);
            window.location = `/dealer/register-success/${response.dealer_id}`;
          } else {
            setOpenBackdrop(false);
            setErrMsg(response.message);
            // console.log('err:', response.message);
          }
          //   setOpenBackdrop(false);
          //   window.location('/register-success');
          //   const response = await DealerServices.createDealer(data)
        }
      }
    } catch (err) {
      console.log(err);
    }
    // console.log({
    //   firstName: firstName,
    //   lastName: lastName,
    //   birthdate: Moment(birthdate).format('DD/MM/YYYY'),
    //   phone: phone,
    //   email: email,
    //   password: password,
    //   confirmPassword: confirmPass,
    // });
  };
  //   const handleSubmit = (event) => {
  //     event.preventDefault();
  //     const data = new FormData(event.currentTarget);
  //     console.log({
  //       firstName: data.get('firstName'),
  //       lastName: data.get('lastName'),
  //       phone: data.get('phone'),
  //       email: data.get('email'),
  //       password: data.get('password'),
  //       confirmPassword: data.get('confirm-password'),
  //     });
  //   };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            สมัครเป็นตัวแทนจำหน่าย
          </Typography>
          <Box
            component="form"
            // noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
            ref={formRef}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  onKeyDown={blockInvalidText}
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="ชื่อ"
                  autoFocus
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  onKeyDown={blockInvalidText}
                  id="lastName"
                  label="นามสกุล"
                  name="lastName"
                  autoComplete="family-name"
                  onChange={(event) => setLastName(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  onKeyDown={blockInvalidNumber}
                  id="birthdate"
                  label="วัน/เดือน/ปีเกิด"
                  name="birthdate"
                  //   minDate={new Date('1940-01-01')}
                  type="date"
                  InputProps={{
                    inputProps: { min: '1940-01-01' },
                  }}
                  InputLabelProps={{ shrink: true, required: true }}
                  onChange={(event) => setBirthdate(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  onKeyDown={blockInvalidNumber}
                  required
                  fullWidth
                  id="phone"
                  label="เบอร์โทรศัพท์"
                  name="phone"
                  onChange={(event) => setPhone(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  type="email"
                  id="email"
                  label="อีเมล"
                  name="email"
                  autoComplete="email"
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="รหัสผ่าน"
                  type={passwordShown ? 'text' : 'password'}
                  id="password"
                  onChange={handleChangePass}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify
                            icon={
                              passwordShown
                                ? 'eva:eye-fill'
                                : 'eva:eye-off-fill'
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirm-password"
                  label="ยืนยันรหัสผ่าน"
                  type={confirmPassShown ? 'text' : 'password'}
                  //   type="password"
                  id="confirm-password"
                  onChange={handleChangeConfirmPass}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleShowConfirmPassword}
                          edge="end"
                        >
                          <Iconify
                            icon={
                              confirmPassShown
                                ? 'eva:eye-fill'
                                : 'eva:eye-off-fill'
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  //   autoComplete="new-password"
                />
              </Grid>
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
            </Grid>
            <Grid sx={{ mt: 2 }}>
              <span style={{ color: 'red' }}>{confirmPassErr}</span>
            </Grid>
            <Grid sx={{ mt: 2 }}>
              <span style={{ color: 'red' }}>{errMsg}</span>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => formRef.current.reportValidity()}
            >
              สมัครสมาชิก
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/dealer/login" variant="body2">
                  มีบัญชีอยู่แล้ว ? เข้าสู่ระบบ
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </ThemeProvider>
  );
}
