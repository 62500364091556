import http from '../http-common';
class DealerServices {
  createDealer = async (dealerDetails) => {
    try {
      const response = await http.post('/dealer', dealerDetails);
      return response.data;
    } catch (err) {
      console.log('Error is:', err);
    }
  };
  getAllDealers = async () => {
    try {
      const response = await http.get('/dealer');
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  getDealerById = async (dealerId) => {
    try {
      const response = await http.get(`/dealer/${dealerId}`);
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  getItemsInDealerCart = async (dealer_id) => {
    try {
      console.log('from service:', dealer_id);
      const response = await http.get(`/dealerCartItem/${dealer_id}`);
      console.log('Response', response);
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  addItemToCart = async (data) => {
    try {
      const response = await http.post(`/dealerCartItem`, data);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
  increaseItem = async (id) => {
    try {
      const response = await http.put(`/dealerCartItem/increase/${id}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
  decreaseItem = async (id) => {
    try {
      const response = await http.put(`/dealerCartItem/decrease/${id}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  deleteItem = async (id) => {
    try {
      const response = await http.delete(`/dealerCartItem/${id}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  updateItem = async (id, data) => {
    try {
      const response = await http.put(`/dealerCartItem/${id}`, data);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };
  updateDealerDetail = async (id, data) => {
    try {
      const response = await http.put(`/dealer/update-details/${id}`, data);
      return response;
    } catch (err) {
      console.log(err);
    }
  };
  updateDealerPassword = async (id, data) => {
    // console.log('dealer service', id, data);
    try {
      const response = await http.put(`/dealer/updatePassword/${id}`, data);
      return response;
    } catch (err) {
      console.log(err);
    }
  };
  createDealerAddress = async (data) => {
    console.log('dealer service:', data);
    try {
      const response = await http.post(`/dealer/address`, data);
      console.log('Response service:', response);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  updateDealerAddress = async (dealerId, data) => {
    // console.log('dealer service:', data);
    try {
      const response = await http.put(`/dealer/address/${dealerId}`, data);
      console.log('Response service:', response);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  getDealerAddress = async (id) => {
    // console.log('from service:', id);
    try {
      const response = await http.get(`/dealer/address/${id}`);
      return response;
    } catch (err) {
      console.log(err);
    }
  };
}
export default new DealerServices();
