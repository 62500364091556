import { LOGIN, LOGOUT } from '../actions/types';

const INITIAL_STATE = {
  isSignedIn: false,
  isInitaialized: false,
  user: null,
};
const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      console.log('LOGIN:', action);
      return {
        ...state,
        isSignedIn: true,
        isInitaialized: true,
        user: action.payload,
      };
    case LOGOUT:
      return { ...state, isSignedIn: false, isInitaialized: false, user: null };
    default:
      return state;
  }
};

export default authReducer;
