import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Stack, Icon } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

import Typography from '@mui/material/Typography';

// import bgImage from 'assets/images/bg-about-us.jpg'
// import MKBox from '../../components/MKBox';
// import ProductServices from '../../services/productServices';
// import DealerProductList from '../../components/Dealer/DealerProductList';

const dropShipDetails = [
  { id: '01', title: 'ไม่มีค่าสมัคร' },
  { id: '02', title: 'มีแคตตาลอคสินค้าให้' },
  { id: '03', title: 'ตัวแทนสามารถตั้งราคาขายเองได้' },
  { id: '04', title: 'สามารถสะสมคะแนนเพื่อรับส่วนลดเพิ่ม' },
];

const rewardDetails = [
  { title: 'ยอดขายสะสม 0 - 10,000 บาท', status: 'Silver' },
  { title: 'ยอดขายสะสม 10,001 - 20,000 บาท', status: 'Gold' },
  { title: 'ยอดขายสะสม 20,001 - 20,000 บาท', status: 'Platinum' },
  { title: 'ยอดขายสะสม 30,001 บาท ขึ้นไป', status: 'Diamond' },
];
const Dealer = () => {
  return (
    <Box
      component="header"
      width="100%"
      sx={{
        height: 450,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Typography variant="h4" sx={{ marginTop: 5, color: '#1C4D8C' }}>
        ตัวแทนจำหน่ายทอยสเปซ
      </Typography>
      <Box width="50%">
        <Grid container spacing={2} sx={{ marginTop: 3 }}>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="contained"
              component={Link}
              to={'/dealer/register'}
              sx={{
                backgroundColor: '#F291C7',
                '&:hover': {
                  backgroundColor: '#F366E4',
                },
              }}
            >
              <Typography sx={{ color: 'black' }}>
                สมัครเป็นตัวแทนจำหน่าย
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="contained"
              component={Link}
              to={'/dealer/login'}
            >
              <Typography>เข้าสู่ระบบ</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginTop: 5, textAlign: 'left' }}>
        <Stack spacing={2} sx={{ alignItems: 'left' }}>
          <Grid container spacing={1}></Grid>
          {dropShipDetails.map((item) => (
            <Grid container spacing={1} key={item.id}>
              <Grid item>
                <StarIcon sx={{ color: '#F2E205' }} />
              </Grid>
              <Grid item>
                <Typography>{item.title}</Typography>
              </Grid>
            </Grid>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};
export default Dealer;
