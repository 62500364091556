import { Box, List, ListSubheader } from '@mui/material';
import { styled } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Navbar from './Header/Navbar';
import StickyFooter from './Footer';
import { useEffect, useState } from 'react';
import categoryServices from '../../services/categoryServices';
import { store } from '../../store';

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  borderRadius: 10,
  paddingTop: 24,
  paddingBottom: theme.spacing(10),
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('lg')]: {
    paddingTop: 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
const MainLayout = () => {
  const [category, setCategory] = useState([]);
  const authUser = store.getState().authReducer.user;
  useEffect(() => {
    const getCat = async () => {
      await categoryServices.getAllCategories().then((res) => {
        setCategory(res);
      });
    };
    getCat();
  }, []);

  const guestMenu = [
    {
      title: 'หน้าแรก',
      path: '/home',
    },
    {
      title: 'หมวดหมู่สินค้า',
      children: category.map((m) => ({
        title: m.category_name,
        path: `/products/category/${m.category_id}`,
      })),
    },
    {
      title: 'รู้จักเรา',
      path: '/about-us',
    },
    {
      title: 'ตัวแทนจำหน่าย',
      path: '/dealer',
    },
    {
      title: 'ติดต่อเรา',
      path: '/contact-us',
    },
	{
      title: 'บทความ',
      path: '/blogs',
    },
  ];
  const dealerMenu = [
    {
      title: 'หน้าแรก',
      path: '/dealer/home',
    },
    {
      title: 'หมวดหมู่สินค้า',
      children: category.map((m) => ({
        title: m.category_name,
        path: `/dealer/products/category/${m.category_id}`,
      })),
    },
    {
      title: 'รู้จักเรา',
      path: '/about-us',
    },
    {
      title: 'ติดต่อเรา',
      path: '/contact-us',
    },
	{
      title: 'บทความ',
      path: '/blogs',
    },
  ];
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '80vh',
        }}
      >
        <List>
          <Header menu={authUser === null ? guestMenu : dealerMenu} />
          <ListSubheader
            sx={{
              backgroundColor: '#85C1E9',
            }}
          >
            <Navbar menu={authUser === null ? guestMenu : dealerMenu} />
          </ListSubheader>
          <MainStyle>
            <Outlet />
          </MainStyle>
        </List>
      </Box>
      <StickyFooter />
    </>
  );
};
export default MainLayout;
