import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import ProductCard from './ProductCard';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductList({ products, ...other }) {
  // console.log('products', products);
  return (
    <Grid container spacing={3} {...other}>
      {products.map((product) => (
        <Grid
          key={product.product_id}
          item
          xs={6}
          sm={6}
          md={3}
          component={Link}
          to={{ pathname: `/products/${product.product_id}` }}
          style={{ textDecoration: 'none' }}
          underline="none"
        >
          <ProductCard product={product} />
        </Grid>
      ))}
    </Grid>
  );
}
