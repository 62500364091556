import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import AuthenServices from '../services/authenServices';
// import config from '../config';
import { store } from '../store';

const GuestGuard = ({ children }) => {
  const isSignedIn = store.getState().authReducer.isSignedIn;
  const navigate = useNavigate();

  useEffect(() => {
    //console.log('isLoggedIn', isSignedIn);
    if (isSignedIn) {
      navigate('/dealer/home', { replace: true });
    }
  }, [isSignedIn, navigate]);

  return children;
};

export default GuestGuard;
