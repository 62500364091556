import { Box, Container, Grid, Link, Icon, Typography, Stack } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import { styled } from '@mui/material/styles';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import CallIcon from '@mui/icons-material/Call';

// import lineLogo from '../../assets/line-logo.png';
import LineBrandIcon from '../../assets/LINE_Brand_icon.png';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';

const LineIcon = styled(Icon)(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',

  backgroundImage: ` url(${LineBrandIcon})`,
}));
const ContactUs = () => {
  return (
    <Container>
      <Box
        component="header"
        width="100%"
        sx={{
          backgroundColor: '#F7DC6F',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" sx={{ marginTop: 5, color: '#1C4D8C' }}>
          ติดต่อเรา
        </Typography>
        <Box width="80%" sx={{ flexGrow: 1, marginTop: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <HomeIcon />
                <Typography variant="body1" textAlign="left">
                  ที่อยู่: 17/8 หมู่8 ถนนบางกรวย-ไทรน้อย ตำบลบางกรวย
                  อำเภอบางกรวย จังหวัดนนทบุรี 11130
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Stack direction="row" spacing={2}>
                <LocalPostOfficeIcon />
                <Typography textAlign="left">hi.toysspace@gmail.com</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Stack direction="row" spacing={2}>
                <CallIcon />
                <Typography
                  textAlign="left"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  0649516541
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Stack direction="row" spacing={2}>
                <LineIcon />
                <Typography
                  target="_blank"
                  component={Link}
                  href="https://lin.ee/0qZRMkY"
                  textAlign="left"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  @toysspace
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Stack direction="row" spacing={2}>
                <InstagramIcon />
                <Typography
                  target="_blank"
                  component={Link}
                  href="https://instagram.com/hi.toysspace"
                  textAlign="left"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  hi.@toysspace
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Stack direction="row" spacing={2}>
                <FacebookIcon />
                <Typography
                  target="_blank"
                  component={Link}
                  href="https://www.facebook.com/toysspac"
                  textAlign="left"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  toysspace
                </Typography>
              </Stack>
            </Grid>
			<Grid item xs={12} mt={2}>
              <Stack direction="row" spacing={2}>
                <MapIcon />
                <Typography
                  target="_blank"
                  component={Link}
                  href="https://www.google.com/maps/place/ทอยสเปซ+สื่อเสริมพัฒนาการและการเรียนรู้สำหรับเด็ก/@13.8038826,100.4855541,17z/data=!3m1!4b1!4m6!3m5!1s0x30e29b17eb301709:0x28624b3b9ea04fe0!8m2!3d13.8038826!4d100.4855541!16s%2Fg%2F11j9cmvxwd"
                  textAlign="left"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  แผนที่การเดินทาง
                </Typography>
              </Stack>
            </Grid>
			<Grid item xs={12} mt={2} mb={10}>
              <div>
                <iframe
                  title="Toy's space map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.5848411060883!2d100.48555410000002!3d13.803882599999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29b17eb301709%3A0x28624b3b9ea04fe0!2z4LiX4Lit4Lii4Liq4LmA4Lib4LiLIOC4quC4t-C5iOC4reC5gOC4quC4o-C4tOC4oeC4nuC4seC4kuC4meC4suC4geC4suC4o-C5geC4peC4sOC4geC4suC4o-C5gOC4o-C4teC4ouC4meC4o-C4ueC5ieC4quC4s-C4q-C4o-C4seC4muC5gOC4lOC5h-C4gQ!5e0!3m2!1sth!2sth!4v1678596157846!5m2!1sth!2sth"
                  width={600}
                  height={450}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
export default ContactUs;
