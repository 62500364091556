import React, {useEffect} from 'react';

// import ReactDOM from 'react-dom';
import { CssBaseline } from '@mui/material';
import { Provider } from 'react-redux';
// import { ThemeProvider, createTheme } from '@mui/material/styles';
// import Button from '@mui/material/Button';
import ThemeConfig from './theme';
import store from './store';
// import Route from './routes';
import Routes from './router';

const App = () => {
  // const [light, setLight] = React.useState(true);
  return (
    // <ThemeProvider theme={light ? themeLight : themeDark}>
    <ThemeConfig>
      <CssBaseline />
      {/* <Provider store={store}> */}
      <Routes />
      {/* </Provider> */}
      {/* <Button onClick={() => setLight((prev) => !prev)}>Toggle Theme</Button> */}
    </ThemeConfig>
    // </ThemeProvider>
  );
};

export default App;
// const rootElement = document.getElementById('root');
// ReactDOM.render(<App />, rootElement);
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
