import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
// import BillService from '../../services/bill.service';
import {
  Box,
  Button,
  Card,
  List,
  ListItem,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Alert,
} from '@mui/material';
import { Link } from 'react-router-dom';

import TableCell from '@mui/material/TableCell';
import Moment from 'moment';
import { StyledTableCell } from '../../../components/StyledTableCell';
import { store } from '../../../store';
import OrderServices from '../../../services/orderServices';
import { useSearchParams } from 'react-router-dom';
// import OrderService from '../../services/order.service';

const OrderSuccess = () => {
  const { dealerId, orderId } = useParams();
  const [orderDetail, setOrderDetail] = useState([]);
  const [searchParams] = useSearchParams();
  const user = store.getState().authReducer.user;

  useEffect(() => {
    const getOrderInvoice = async () => {
      if (user.id === Number(dealerId)) {
        await OrderServices.getOrderById(String(orderId)).then((res) => {
          console.log('Response:', res);
          setOrderDetail(res);
        });
      }
    };
    getOrderInvoice();
  }, [user, dealerId, orderId]);

  function ccyFormat(num) {
    return Number(num).toFixed(2);
  }
  const invoice = () => {
    console.log('detail:', orderDetail);
    if (orderDetail.order_id !== undefined) {
      return (
        <>
          <Alert severity="success">สั่งซื้อสินค้าสำเร็จ!</Alert>
          <List>
            <ListItem>
              <Typography>
                คำสั่งซื้อที่: {orderDetail.order_text_id}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                วันที่สั่งซื้อ:{' '}
                {Moment(orderDetail.created_at).format('DD/MM/YYYY HH:mm:ss')}
              </Typography>
            </ListItem>
            <ListItem>
              <Card sx={{ marginTop: 4, width: '100%' }}>
                <Box
                  sx={{
                    justifyContent: 'center',
                    margin: 4,
                    alignItems: 'center',
                  }}
                >
                  <Table
                    sx={{ minWidth: 250, marginBlock: 4 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>รายการ</StyledTableCell>
                        <StyledTableCell>ราคาต่อหน่วย</StyledTableCell>
                        <StyledTableCell>จำนวน</StyledTableCell>
                        <StyledTableCell align="right">ราคา</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {JSON.parse(orderDetail.order_details).map((row) => (
                        <TableRow key={row.dealer_cart_item_id}>
                          <TableCell>{row.product_name}</TableCell>
                          <TableCell>{row.dealer_price}</TableCell>
                          <TableCell>{row.quantity}</TableCell>
                          <TableCell align="right">
                            {ccyFormat(row.quantity * row.dealer_price)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <Grid item xs={12} sm container>
                    <Grid item xs container spacing={2}>
                      <Grid item sx={{ marginTop: 1 }}>
                        <Typography>รวมค่าสินค้า</Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {ccyFormat(orderDetail.order_totalPrice)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container spacing={2}>
                      <Grid item sx={{ marginTop: 1 }}>
                        <Typography>ส่วนลด</Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography color="red">
                        -{ccyFormat(orderDetail.discount)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container spacing={2}>
                      <Grid item sx={{ marginTop: 1 }}>
                        <Typography>จำนวนเงินรวมทั้งสิ้น</Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography>{ccyFormat(orderDetail.total)}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </ListItem>
          </List>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Typography>{searchParams.get('orderId')}</Typography>
      {invoice()}
      <Grid container justifyContent="center" sx={{ marginTop: 4 }}>
        <Grid item>
          <Button variant="outlined" component={Link} to={`/dealer/home`}>
            กลับสู่หน้าหลัก
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default OrderSuccess;
