import React, { useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';

const MyNavLink = React.forwardRef((props, ref) => (
  <NavLink
    ref={ref}
    to={props.to}
    className={({ isActive }) =>
      `${props.className} ${isActive ? props.activeClassName : ''}`
    }
  >
    {props.children}
  </NavLink>
));
const NavMenuItem = ({ item, onCloseMenu }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  if (item.children) {
    return (
      <>
        <List>
          <ListItemButton onClick={handleOpen}>
            <ListItemText>
              <Typography>{item.title}</Typography>
            </ListItemText>
            {open ? <IconChevronUp /> : <IconChevronDown />}
          </ListItemButton>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <List onClick={onCloseMenu}>
              {item.children.map((menu) => {
                return (
                  <ListItemButton
                    component={MyNavLink}
                    to={menu.path}
                    activeClassName="Mui-selected"
                    key={menu.title}
                    sx={{ pl: 7 }}
                  >
                    <ListItemText>
                      <Typography>{menu.title}</Typography>
                    </ListItemText>
                  </ListItemButton>
                );
              })}
            </List>
          </Collapse>
        </List>
        <Divider />
      </>
    );
  }
  return (
    <>
      <List onClick={onCloseMenu}>
        <ListItemButton
          component={MyNavLink}
          to={item.path}
          activeClassNam="Mui-selected"
        >
          <ListItemText>
            <Typography>{item.title}</Typography>
          </ListItemText>
        </ListItemButton>
      </List>
      <Divider />
    </>
  );
};
export default NavMenuItem;
