import { useEffect, useState } from 'react';
import React from 'react';
// material
import { Box, Card, Container, Stack, Typography } from '@mui/material';
// components
import DealerProductList from '../../../components/Dealer/DealerProductList';

//Service
import ProductSevices from '../../../services/productServices';
import { useParams } from 'react-router';
import CategoryServices from '../../../services/categoryServices';
import ProductList from '../../../components/ProductList';

// --------------------------------Products for dealer--------------------------------------

export default function Category() {
  const [products, setProducts] = useState([]);
  const [catTitle, setCatTitle] = useState('');
  const { id } = useParams();
  useEffect(() => {
    const getProducts = async () => {
      const response = await ProductSevices.getProductByCat(id);
      setProducts(response);
      await CategoryServices.getCategoryById(id).then((res) => {
        setCatTitle(res.category_name);
      });
    };
    getProducts();
  }, [id]);

  const catProducts = () => {
    if (products.length > 0) {
      return <ProductList products={products} />;
    } else {
      return (
        <Card sx={{ height: '100px' }}>
          <Box
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Typography marginTop={5}>ยังไม่มีสินค้าประเภทนี้</Typography>
          </Box>
        </Card>
      );
    }
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" sx={{ mb: 5 }}>
          {catTitle}
        </Typography>
      </Stack>
      {catProducts()}
      {/* <DealerProductList products={products} /> */}
    </Container>
  );
}
