export const blockInvalidText = (e) =>
  [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '!',
    '@',
    '#',
    '$',
    '%',
    '@',
    '^',
    '&',
    '*',
    '(',
    ')',
    '_',
    '=',
    '?',
    '|',
    '+',
    '-',
    '>',
    '<',
    '"',
    ':',
    ';',
    ',',
    `'`,
    '[',
    ']',
    '{',
    '}',
    '/',
    '~',
    '`',
    'ๅ',
    '๑',
    '๒',
    '๓',
    '๔',
    '๕',
    '๖',
    '๗',
    '๘',
    '๙',
    '๐',
    // 'ภ',
    // 'ถ',
    // 'ุ',
    // 'ู',
    // 'ค',
    // 'ต',
    // 'จ',
    // 'ข',
    // 'ช',
    // 'ๆ',
    // 'ำ',
    // 'ฎ',
    // 'พ',
    // 'ฑ',
    // 'ะ',
    // 'ธ',
    // 'ั',
    // 'ํ',
    // 'ี',
    // '๊',
    // 'ร',
    // 'ณ',
    // 'น',
    'ฯ',
    // 'ย',
    // 'ญ',
    // 'บ',
    // 'ฐ',
    // 'ล',
    ',',
    // 'ฟ',
    // 'ฤ',
    // 'ห',
    // 'ฆ',
    // 'ก',
    // 'ฏ',
    // 'ด',
    // 'โ',
    // 'เ',
    // 'ฌ',
    // '้',
    // '็',
    // '่',
    // '๋',
    // 'า',
    // 'ษ',
    // 'ส',
    // 'ศ',
    // 'ว',
    // 'ซ',
    // 'ง',
    // '.',
    // 'ผ',
    '(',
    // 'ป',
    ')',
    // 'แ',
    // 'ฉ',
    // 'อ',
    // 'ฮ',
    // 'ิ',
    // 'ฺ',
    // 'ื',
    // '์',
    // 'ท',
    '?',
    // 'ม',
    // 'ฒ',
    // 'ใ',
    // 'ฬ',
    // 'ฝ',
    // 'ฦ',
  ].includes(e.key) && e.preventDefault();
