import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Box, Container, Grid, List, ListItem, TextField } from '@mui/material';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import ProductServices from '../../services/productServices';

const ProductDetails = () => {
  const { id } = useParams();
  const [productInfo, setProductInfo] = useState([]);
  const [allPhotos, setAllPhotos] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [arrow, setArrow] = useState(false);
  useEffect(() => {
    const getProductDetails = async () => {
      const response = await ProductServices.getProductInfo(id);
      console.log(response);
      setProductInfo(response);
      await ProductServices.getProductPictures(id).then((res) => {
        console.log('Products-photos', res);
        setAllPhotos(res);
        if (res.length > 1) {
          setArrow(true);
        }
      });
    };
    getProductDetails();
  }, [id]);
  const fadeProperties = {
    infinite: false,
    indicators: true,
    autoplay: false,
    arrows: arrow,
  };

  return (
    <Container sx={{ position: 'relative' }}>
      <Box
        // component="header"
        width="100%"
        height="450px"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <div className="slide-container">
              <Fade {...fadeProperties}>
                {allPhotos.map((slideImage, index) => (
                  <div className="slide" key={index}>
                    <div
                      style={{
                        backgroundImage: `url(${slideImage.image_url})`,
                      }}
                    ></div>
                  </div>
                ))}
              </Fade>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: { md: '150px' },
                marginLeft: { md: '100px', sm: '50px' },
              }}
            >
              <List sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <ListItem>ชื่อสินค้า: {productInfo.product_name}</ListItem>
                <ListItem>ประเภท: {productInfo.category_name}</ListItem>
                <ListItem>
                  รายละเอียด: {productInfo.product_description}
                </ListItem>
                <ListItem>ราคา: {productInfo.price} บาท</ListItem>
                
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default ProductDetails;
